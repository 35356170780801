import React from 'react'
import ReactMarkdown from 'react-markdown'
import * as styles from './fullPageText.module.scss'

export default ({ block }) => {
  return (
    <div className={styles.section} id={block.type}>
      {block.text && <ReactMarkdown>{block.text}</ReactMarkdown>}
    </div>
  )
}