import React, { useContext } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './textAndImage.module.scss'
import ReactMarkdown from 'react-markdown'

export default ({ block: { LoR, template, heading, text, image, image_caption, image_width, video_url } }) => {
  return (
    <div className={styles.section + ` ${LoR ? `${styles.grey}` : ""}`} id={template}>
      <div className={styles.left + ` ${LoR ? `${styles.reverse}` : ""}`}>
        <h1 className={styles.heading}>{heading}</h1>
        {text && <ReactMarkdown>{text}</ReactMarkdown>}
      </div>
      <div className={styles.right + `${video_url ? ` ${styles.video}` : ""}`}>
        {image &&
          <Image src={image} className={styles.image} style={{ width: `${image_width ?? 100}%`}}/>}
        {image_caption && parse(image_caption)}
        {video_url &&
          <div className={styles.videoContainer}>
            <div className={styles.videoWrapper}>
              <iframe
                width="560"
                height="349"
                src={`https://www.youtube.com/embed/${video_url.split('/')[video_url.split('/').length - 1]}`}
                allowFullScreen>
              </iframe>
            </div>
          </div>}
      </div>
    </div>
  )
}