// extracted by mini-css-extract-plugin
export var active = "bd_8";
export var arrow = "bd_5";
export var border = "bd_dq";
export var button = "bd_bL";
export var column = "bd_cb";
export var columns = "bd_b9";
export var container = "bd_b";
export var description = "bd_dl";
export var dot = "bd_dv";
export var footer = "bd_cl";
export var footerText = "bd_cm";
export var heading = "bd_G";
export var icon = "bd_br";
export var includes = "bd_ds";
export var info = "bd_cP";
export var left = "bd_bH";
export var mobileOption = "bd_dt";
export var option = "bd_dn";
export var optionText = "bd_dp";
export var options = "bd_dm";
export var padding = "bd_dB";
export var plans = "bd_dr";
export var popular = "bd_dz";
export var price = "bd_dw";
export var right = "bd_bT";
export var section = "bd_j";
export var show = "bd_bj";
export var subHeading = "bd_H";
export var subText = "bd_dy";
export var suffix = "bd_dx";
export var title = "bd_g";
export var waveOne = "bd_q";
export var waveThree = "bd_c4";
export var waveTwo = "bd_n";
export var waves = "bd_c3";