import React, { useState, useEffect, useContext } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './contact.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const [category, setCategory] = useState(block.categories ? block.categories[0] : null);


  return (
    <div className={styles.section} id={block.type}>
      <h2 className={styles.title}>{block.blockTitle}</h2>
      <div className={styles.body}><ReactMarkdown>{block.body}</ReactMarkdown></div>
      <div className={styles.footer}>
        <p className={styles.footerText}>{block.contactTicketStatusText ?? ''}</p>
        <SharedTextButton button={block.sharedTextButton} />
      </div>
    </div>
  )
}