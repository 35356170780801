import React from 'react'
import { Image } from '../../'

export default ({ styles, field, formValues, setFormValues, handleInputChange, selectOpen, setSelectOpen, getStyle, handleOptionClick }) => (
  <>
    <p className={styles.textLabel}>{field.label}</p>
    <input 
      className={styles.dropdownInput} 
      name={field.label}
      value={formValues[field.label]}
      onChange={handleInputChange}
    />
    <div 
      onClick={() => setSelectOpen(!selectOpen)} 
      className={styles.select + ` ${selectOpen ? `${styles.open}` : formValues[field.label] ? `${styles.selected}` : ''}` + ` ${getStyle(field.label)}`}
    >
      <Image 
        src={formValues[field.label] && !selectOpen ? "/chevron-down.svg" : "/chevron-down-dark.svg"} className={styles.arrow} 
      />
      <p className={styles.placeholder + ` ${formValues[field.label] ? `${styles.hide}` : ''}`}>{field.placeholder}</p>
      {field.options.map((option) => (
        <button 
          key={option} 
          type='button' 
          onClick={() => handleOptionClick(field.label, option, selectOpen, setSelectOpen, setFormValues)} 
          className={styles.dropdownOption + ` ${formValues[field.label] === option ? `${styles.active}` : ''}` + ` ${selectOpen ? `${styles.open}` : ''}`}>
          {option}
        </button>
      ))}
    </div>
  </>
)