import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { ForestryLink } from '../../../../utilities'

import * as styles from './columns.module.scss'

export default ({ }) => {
  
  const { site: { siteMetadata: { footer: { columns } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        footer {
          columns {
            title
            links {
              article
              label
              page
              link_type
              url
            }
          }
        }
      }
    }
  }`)

  return (
    <div className={styles.container}>
      <div className={styles.columns}>
        {columns.map(column => (
          <div key={column.title} className={styles.column}>
            <p className={styles.title}>{column.title}</p>
            {column.links.map(link => (
              <div key={link.label}>
                {(link.link_type === "Article" || link.link_type === "Page") ? (
                  <ForestryLink key={link.page} className={styles.link} activeClassName={styles.active} to={link.page || link.article}>
                    <p>{link.label}</p>
                  </ForestryLink>
                ) : <a className={styles.link} href={link.url}><p>{link.label}</p></a>}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}