// extracted by mini-css-extract-plugin
export var active = "bh_8";
export var arrow = "bh_5";
export var button = "bh_bL";
export var buttonMobile = "bh_dQ";
export var column = "bh_cb";
export var columns = "bh_b9";
export var container = "bh_b";
export var description = "bh_dl";
export var disclaimer = "bh_cQ";
export var disclaimerButton = "bh_dL";
export var disclaimerContainer = "bh_dS";
export var disclaimerText = "bh_dW";
export var fadeBottom = "bh_dV";
export var fadeTop = "bh_dT";
export var featureFooter = "bh_dN";
export var footer = "bh_cl";
export var footerMobile = "bh_dP";
export var heading = "bh_G";
export var highlight = "bh_dM";
export var icon = "bh_br";
export var iconContainer = "bh_b0";
export var left = "bh_bH";
export var padding = "bh_dB";
export var popular = "bh_dz";
export var prefix = "bh_dK";
export var recommend = "bh_dH";
export var recommended = "bh_dJ";
export var right = "bh_bT";
export var section = "bh_j";
export var show = "bh_bj";
export var subHeading = "bh_H";
export var suffix = "bh_dx";
export var title = "bh_g";
export var waveContainer = "bh_dR";
export var waveOne = "bh_q";
export var waveThree = "bh_c4";
export var waveTwo = "bh_n";
export var waves = "bh_c3";