import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Image, ForestryLink } from '../../../../../utilities'

import * as styles from './smallLinks.module.scss'
import { Context } from '../../../../context'

export default ({ setOpen, activeMenu, setActiveMenu }) => {
  const { site: { siteMetadata: { mobileMenu: { smallLinks } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        mobileMenu {
          smallLinks {
            label
            page
            url
            form
            sublinks {
              page
              label
              url
              form
              icon
            }
          }
        }
      }
    }
  }`)

  const context = useContext(Context);

  const handleClick = () => {
    setOpen(false)
  }

  const handleFormClick = (form) => {
    context.setForm(form);
    context.setShowForm(true);
    handleClick();
  }

  // const handleLeave = () => {
  //   setActiveMenu(undefined);
  // }

  return (
    <div className={styles.links}>
      {smallLinks.map((link, i) => {
        if (link.sublinks) {
          return (
            <div className={styles.megaMenus} key={i}>
              <button className={styles.link} onClick={() => setActiveMenu(link.label)}>{link.label}</button>
              <div className={styles.megaMenu + ` ${activeMenu === link.label ? `${styles.show}` : ""}`} >
                <div className={styles.innerContainer}>
                  <ul>
                    {link.sublinks.map((link,idx) => (
                      <li key={`${i}${idx}`}>
                        {link.icon && <Image className={styles.linkIcon} src={link.icon} />}

                        {
                            link.url ? (
                              <a className={styles.sublink} href={link.url} target="_blank" rel="noopener noreferrer">
                                {link.label}
                              </a>
                            ) : link.form ? (
                              <button className={styles.sublink} onClick={() => handleClick(link)}>{link.label}</button>
                            ) : <ForestryLink className={styles.sublink} activeClassName={styles.active} to={link.page}>
                            </ForestryLink>
                          }
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
          </div>
          )
        }
        if (link.page) {
          return <ForestryLink key={i} className={styles.link} activeClassName={styles.active} to={link.page} onClick={handleClick}>{link.label}</ForestryLink>
        }
        if (link.form) {
          return <button className={styles.link} key={i} onClick={() => handleFormClick(link.form)}>{link.label}</button>
        }
        if (link.url) {
          return <a className={styles.link} key={i} href={link.url} onClick={handleClick}>{link.label}</a>
        }
      })}
    </div>
  )
}