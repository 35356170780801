import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { ForestryLink } from '../../../../utilities'

import * as styles from './contact.module.scss'

export default ({ }) => {
  
  const { site: { siteMetadata: {
    footer: { contactNumbers, contactEmails },
    contact: { address, googleMapsLink },
  } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        footer {
          contactNumbers {
            text
            textNumber
            linkNumber
          }
          contactEmails {
            label
            email
          }
        }
        contact {
          address
          googleMapsLink
        }
      }
    }
  }`)

  return (
    <div className={styles.container}>
      <div className={styles.numbers}>
        {contactNumbers.map((number, i) => (
          <p key={i} className={styles.number}>{number.text}<span><a href={`tel:${number.linkNumber}`}>{number.textNumber}</a></span></p>
        ))}
      </div>
      <div className={styles.emails}>
        {contactEmails.map(email => (
          <p key={email.label} className={styles.email}>{email.label}<span><a href={`mailto:${email.email}`}>{email.email}</a></span></p>
        ))}
      </div>
      <div className={styles.location}>
        <a href={googleMapsLink} target="__blank" rel="noreferrer">{address}</a>
      </div>
    </div>
  )
}