
import React, { useState, useContext, useEffect } from 'react'
import { MobileMenu } from './blocks/mobile/'
import { Icons, Logo, Links, MegaMenus, MyBtc, SearchBox, SmallLinks, TopBanner } from './blocks'
import { Context } from '../context'
import { SharedForm } from '../../utilities'
import * as styles from './header.module.scss'
import { Button } from './blocks/mobile'

const searchIndices = [
  { name: `Pages`, title: `Pages` }, 
  { name: 'Business Pages', title: `Business Pages`},
  { name: 'Residential Pages', title: `Residential Pages`}]

export default ({ }) => {  
  const [activeMenu, setActiveMenu] = useState('');
  const context = useContext(Context);
  const [open, setOpen] = useState(false);

  const handleHover = e => {
    if (context && context.scrolled)
      context.setScrolled(false);
  }

  const handleLeave = e => {
    if (context.scroll > 15)
      context.setScrolled(true);
  }

  return (
    <header className={context && context.showForm ? `${styles.hide}` :""}>
      <SharedForm />
      <TopBanner />
      <div onMouseEnter={handleHover} onMouseLeave={handleLeave} className={styles.container + ` ${context && context.scrolled && !activeMenu ? `${styles.scrolled}` : ""}`}>
        <div className={styles.background + ` ${context && context.scroll > 10 ? `${styles.scrolled}` : ""}`}></div>
        <Logo activeMenu={activeMenu} />
        <SearchBox />
        <Links 
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu} />
        <SmallLinks activeMenu={activeMenu} setActiveMenu={setActiveMenu}/>
        <Icons activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
        <MegaMenus activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
        <Button 
          open={open} 
          setOpen={setOpen}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
        />
        <MobileMenu 
          activeMenu={activeMenu} 
          setActiveMenu={setActiveMenu} 
          open={open}
          setOpen={setOpen}
        />
        <MyBtc activeMenu={activeMenu} setActiveMenu={setActiveMenu}/>
      </div>
    </header>
  )
}