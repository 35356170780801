// extracted by mini-css-extract-plugin
export var buttons = "V_ck";
export var column = "V_cb";
export var columns = "V_b9";
export var container = "V_b";
export var down = "V_c7";
export var download = "V_c6";
export var footer = "V_cl";
export var information = "V_c5";
export var section = "V_j";
export var show = "V_bj";
export var text = "V_cg";
export var title = "V_g";
export var units = "V_c8";
export var up = "V_db";
export var upload = "V_c9";
export var waveOne = "V_q";
export var waveThree = "V_c4";
export var waveTwo = "V_n";
export var waves = "V_c3";