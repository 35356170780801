import React, { useContext } from 'react'
import { Context } from '../../components/context'
import Form from './components/form'

export default () => {
  const context = useContext(Context);

  return (
    <Form {...{
      showForm: context?.showForm,
      formContext: context?.form,
      onClose: () => {
        context && context.setShowForm(false);
      }
    }} />
  )
}