import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { Trim, Image } from '../../../../../utilities'
import { Context } from '../../../../context'
import * as styles from './searchResult.module.scss'

export default ({ }) => {
  const context = useContext(Context);

  if (context && context.search && typeof window !== 'undefined')
    window.scrollTo(0,0);

  const handleClick = (result) => {
    let componentPath = result.type ? `${result.slug}#${parseTemplate(result.type['value'])}` 
    : result.slug ? result.slug 
    : result.relativeDirectory ? parsePath(result) : '';
    context.setPath(componentPath)
    context.setSearch('');
    context.setResults([]);
  }

  const parseQ = question => {
    return encodeURIComponent(
      question.split('<em>').join('').split('</em>').join('').trim()
    );
  }
  
  const parseSlug = result => {
    const path = result.relativeDirectory;

    if (path === 'blog-articles')
      return `/blog/${result.slug}`
    else if (path === 'support-articles')
      return `/support/${result.slug}`
    else return result.slug.startsWith("/") ? result.slug : `/${result.slug}`
  }

  const parsePath = result => {
    const path = result.relativeDirectory;

    if (path === 'faq') {
      const subcategory = (result.subcategory ?? '').split(' ')[0]
      return `/support?faq=${parseQ(result.title['value'])}&category=${subcategory}`
    } else if (path === 'blog-articles')
      return `/blog/${result.slug}`
    else if (path === 'support-articles')
      return `/support/${result.slug}`
    else return '/support'
  }

  const parseTemplate = template => {
    return template.split("<em>").join('').split("</em>").join('');
  }

  const handleClose = () => {
    context.setSearch('');
    context.setResults([]);
  }

  return (
    <section className={styles.section + ` ${context && context.results.length && context.search ? `${styles.show}` : ""}`}>
      <div className={styles.logo}>
        <Image src={'/algolia-blue-mark.png'} className={styles.logoIcon}/>
      </div>
      <button className={styles.close} onClick={handleClose}>X</button>
      <h2 className={styles.heading}>found {context && context.count} results for '{context && context.search}'</h2>
      <div className={styles.container}>
        {context && context.results.map((result,i) => (
          <div key={i} className={styles.linkContainer}>
            <Link onClick={() => handleClick(result)} className={styles.title} to={
              result.type ? `${result.slug}#${parseTemplate(result.type['value'])}` : result.slug ? parseSlug(result) :
              result.relativeDirectory ? parsePath(result) : ''}>
              <Trim text={result.title['value']} />
            </Link>
            <Link onClick={() => handleClick(result)} className={styles.value} to={
              result.type ? `${result.slug}#${parseTemplate(result.type['value'])}` : result.slug ? parseSlug(result) :
              result.relativeDirectory ? parsePath(result) : ''}>
                <Trim text={result.text['value']} />
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}