// extracted by mini-css-extract-plugin
export var body = "bv_c";
export var buttons = "bv_ck";
export var container = "bv_b";
export var icon = "bv_br";
export var icons = "bv_bs";
export var image = "bv_k";
export var items = "bv_fv";
export var label = "bv_cF";
export var mobileButtons = "bv_fw";
export var name = "bv_ft";
export var section = "bv_j";
export var selected = "bv_bb";
export var show = "bv_bj";
export var title = "bv_g";