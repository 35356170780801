import React, { useState, useRef, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Image, ForestryLink } from '../../../../../utilities'

import * as styles from './myBtc.module.scss'

export default ({ activeMenu, setActiveMenu }) => {
  const { site: { siteMetadata: { mobileMenu: { myBtcLinks } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        mobileMenu {
          myBtcLinks {
            icon
            text
            url
          }
        }
      }
    }
  }`)

  

  return (
    <div className={styles.megaMenus}>
        <div className={styles.megaMenu + ` ${activeMenu === 'My BTC' ? `${styles.show}` : ""}`}>
          <div className={styles.left}>
            <h3>My BTC</h3>
            <ul>
              {myBtcLinks.map((link,i) => (
                <li key={link.page}>
                  <a className={styles.link} target='_blank' rel='noreferrer' href={link.url}>
                    <Image className={styles.linkIcon} src={link.icon} />
                    <p>{link.text}</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
    </div>
  )
}