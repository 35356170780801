import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export default({ text }) => {
  let trimmed = '';
  if (text.length > 150) {
    for (let i = 0; i < text.length; i++) {
      trimmed += text[i];
      if (text[i] === ' ' && i > 150) {
        trimmed += '...';
        break;
      }
    }
  } else trimmed = text;

  return <ReactMarkdown>{trimmed}</ReactMarkdown>
}