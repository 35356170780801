export default (formContext, nodes, setForm, setFormValues) => {
  const formTitle = formContext ?? '';
  const form = nodes.filter(node => node.childFormsJson.formTitle === formTitle)[0];
  setForm(form);
  setFormValues(() => {
    const initVals = {};
    if (form && form.childFormsJson) {
      form.childFormsJson.formPages.forEach(page => page.formFields.forEach(field => {
        if (['checkboxes', 'radio'].includes(field.type)) {
          initVals[field.label] = {};
          field.options.forEach(option => initVals[field.label][option] = false);
        } else if (field.type !== 'hidden')
          initVals[field.label] = '';
      }))
    }
    return initVals
  })
}