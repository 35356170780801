import React from 'react'
import * as styles from './privacyPolicy.module.scss'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  console.log(block)


  return (
    <section className={styles.section}>
      <h1 className={styles.heading}>{block.heading}</h1>
      <div className={styles.privacy}><ReactMarkdown>{block.privacy}</ReactMarkdown></div>
    </section>
  )
}