import React, { useState, useCallback, useEffect } from 'react'
import { Image, ForestryLink } from '../../../../utilities'
import { Link } from 'gatsby'
import * as styles from './solutions.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const [selected, setSelected] = useState(0);
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(false)
    const timer = setTimeout(() => setShow(true), 100);
    return (() => clearTimeout(timer));
  }, [selected])

  return (
    <div className={styles.section} id={block.type}>
      <h2 className={styles.title}>{block.blockTitle}</h2>
      {block.solutionsCategories.map((category, i) => (
        <button 
          onClick={() => setSelected(i)}
          className={styles.name + ` ${selected === i ? `${styles.selected}` : ""}`} 
          onMouseOver={() => setSelected(i)}
          key={category.name}>{category.name}
        </button>
      ))}
      <div className={styles.items}>
        {block.solutionsCategories.map((category, i) => (
          <div key={i} className={styles.container + ` ${show ? `${styles.show}` : ""}`} id={`solutions${i}`}>
            {selected === i && <><div className={styles.icons}>
              {category.icons.map((icon, i) => {
                return icon.type === 'page' ? (
                  <ForestryLink to={icon.page} key={i} className={styles.icon}>
                    <Image src={icon.icon} className={styles.image} />
                    <p className={styles.label}>{icon.label}</p>
                  </ForestryLink>
                ) : (
                  <Link to={icon.url} key={i} className={styles.icon}>
                    <Image src={icon.icon} className={styles.image} />
                    <p className={styles.label}>{icon.label}</p>
                  </Link>
                )
              })}
            </div>
            <ReactMarkdown className={styles.body}>{category.body}</ReactMarkdown>
            <div className={styles.mobileButtons}>
              {block.solutionsButtons.map(({sharedTextButton}, i) => (
                <SharedTextButton key={i} button={sharedTextButton} />
              ))}
            </div></>}
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        {block.solutionsButtons.map(({sharedTextButton}, i) => (
          <SharedTextButton key={i} button={sharedTextButton} />
        ))}
      </div>
    </div>
  )
}