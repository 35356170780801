import React, { createContext, useEffect, useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import algoliasearch from "algoliasearch"
import { useDebounce } from "@react-hook/debounce"
import { faqHits } from './faqHits'
import { Loader } from '@googlemaps/js-api-loader'

type Faq = [string, { answer: string, subcategory: string[], target: string }]

const Context = createContext(null)

const MyContextProvider = ({ queryData, children }) => {
  const [faqWithHits, setFaqWithHits] = useState<Faq[]>([]);
  const [faqWithoutHits, setFaqWithoutHits] = useState<Faq[]>([]);
  const [scroll, setScroll] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [width, setWidth] = useState(null);
  const [recommended, setRecommended] = useState(null)
  const [showRecommended, setShowRecommended] = useState(false);
  const [search, setSearch] = useState('');
  const [siteSearch, setSiteSearch] = useDebounce('', 500)
  const [results, setResults] = useState([]);
  const [form, setForm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [count, setCount] = useState(0);
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [path, setPath] = useState('');
  const QUERY = '(prefers-reduced-motion: no-preference)';
  const isRenderingOnServer = typeof window === 'undefined';
  const getInitialState = () => {
    return isRenderingOnServer ? true : !window.matchMedia(QUERY).matches;
  };
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(getInitialState);

  const nodes = queryData?.allFaqJson?.nodes ?? []

  const loader = new Loader({
    apiKey: process.env.GATSBY_GOOGLE_MAPS_API as string,
    version: "weekly",
  });

  useEffect(() => {
    const mediaQueryList = window.matchMedia(QUERY);

    const listener = event => {
      setPrefersReducedMotion(!event.matches);
    };

    mediaQueryList.addEventListener('change', listener);

    return () => {
      mediaQueryList.removeEventListener('change', listener);
    }
  }, []);

  const compareFaqHits = (a, b) => {
    return faqHits[b.question] - faqHits[a.question];
  }

  useEffect(() => {
    if (nodes && nodes.length) {
      const withHits = nodes
        .filter(({ question }) => !!faqHits[question])
      
      const withoutHits = nodes.filter(({ question }) => !faqHits[question])

      const toFaq = (({ question, contents, subcategory, target }) => (
        [question, {answer: contents, subcategory, target}]
      ))

      setFaqWithHits(withHits.sort(compareFaqHits).map(toFaq));
      setFaqWithoutHits(withoutHits.map(toFaq));
    }
    
  }, [nodes])

  useEffect(() => {
    const updatePosition = () => {
      setWidth(window.innerWidth);
    }
    setWidth(window.innerWidth);

    window.addEventListener('resize', updatePosition);
    return (() => {
      window.removeEventListener('resize', updatePosition);
    })

  }, [width])
  
  const client = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  const queries = [
    {
      indexName: 'Pages',
      query: search,
    }, 
    {
      indexName: 'Support Articles',
      query: search,
    },
    {
      indexName: 'Support FAQ',
      query: search,
    },
    {
      indexName: 'Blog',
      query: search,
    }
  ];
  
  const filterAttributes = hit => {
    let matchedFull = [];
    let matchedPartial = [];
    let data = {};
  
    const filter = (data) => {
      if (data && data['title'] && data['text']) {
        const titleMatch = data['title']['matchLevel'];
        const textMatch = data['text']['matchLevel'];
        const tagsMatch = data['tags']

        const tagMatches = (type) =>
          (Array.isArray(tagsMatch) && tagsMatch.some(x => x['matchLevel'] === type))
        
        if (
          titleMatch === 'full' ||
          textMatch === 'full' ||
          tagMatches('full')
        )
          matchedFull.push(data);
        else if (
          titleMatch === 'partial' ||
          textMatch === 'partial' ||
          tagMatches('partial')
        )
          matchedPartial.push(data);
      }
    }

    const highlights = hit['_highlightResult'];
    
    if (highlights['contents'] ?? highlights['content'] ?? highlights['body'] ?? highlights['tags'] ?? highlights['question']) {
      data = {
        title: highlights['question'] ?? highlights['title'],
        text: highlights['contents'] ?? highlights['content'] ?? highlights['body'],
        relativeDirectory: hit['relativeDirectory'],
        subcategory: hit['subcategory'],
        category: hit['category'],
        slug: hit['slug'],
        date: hit['date'],
        relativePath: hit['relativePath'],
        tags: hit['tags']
      }
      filter(data);
      
    } else if (highlights) {
      for (const [key, value] of Object.entries(highlights)) {
        if (Number.isInteger(+key) && value) {
          data = {
            title: value['blockTitle'],
            text: value['text'],
            type: value['type'],
            slug: hit['slug']
          }
          filter(data);
        }
      } 
    }
    return { matchedFull, matchedPartial }
  }

  useEffect(() => {
    if (search) {
      client.multipleQueries(queries).then(({ results }) => {
        let fullResults = [];
        let partialResults = [];
        results && results.length && results.forEach(result => {
          result.hits.forEach(hit => {
            const { matchedFull, matchedPartial } = filterAttributes(hit);
            fullResults = fullResults.concat(matchedFull);
            partialResults = partialResults.concat(matchedPartial);
          })
        })
        setResults([...fullResults, ...partialResults])
        setCount(fullResults.length + partialResults.length)
      });
    }
  }, [siteSearch]);

  useEffect(() => {
    setSiteSearch(search)
  }, [search])

  useEffect(() => {
    loader.load().then((google) => {
      navigator.geolocation.getCurrentPosition(async position => {
        const geocoder = new google.maps.Geocoder;
        geocoder.geocode({
          location: {
            lat: +position.coords.latitude, 
            lng: +position.coords.longitude
          }
        }, (results => {
  
          results && results.length && results.forEach(result => {
            if (result.types && result.types.includes('locality'))
              setCity(result.formatted_address.split(',')[0]);
            else if (result.types && result.types.includes('postal_code'))
              setZip(result.formatted_address.split(',')[1].slice(-5));
              return;
          })
        }))
      })
    })
  }, [typeof navigator !== 'undefined' && navigator.geolocation])

  return (
    <Context.Provider value={{scroll, setScroll, scrolled, setScrolled, width, recommended, setRecommended, 
      showRecommended, setShowRecommended, faqWithHits, faqWithoutHits, search, setSearch, count, setCount, 
      results, setResults, prefersReducedMotion, form, setForm, showForm, setShowForm, city, setCity, zip, path, setPath, loader }}>
      {children}
    </Context.Provider>)
}

const ContextProvider = ({ children }) => <StaticQuery
  query={
    graphql`
    {
      allFaqJson {
        nodes {
          target
          subcategory {
            subcategory
          }
          question
          tags
          contents
        }
      }
    }`
  }
  render={queryData => <MyContextProvider {...{ queryData, children }} />}
/>

export { Context, ContextProvider }