import algoliasearch from "algoliasearch"
import React, { useEffect } from "react"
import SearchBox from "./searchBox"

export default ({search, setSearch, setCount, setResults, label}) => {
  const client = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  const queries = [
    {
      indexName: 'Support Articles',
      query: search,
    },
    {
      indexName: 'Support FAQ',
      query: search,
    },
  ];
  
  const filterAttributes = hit => {
    let matchedFull = [];
    let matchedPartial = [];
    let data = {};
  
    const filter = (data) => {
      if (data && data['title'] && data['text']) {
        const titleMatch = data['title']['matchLevel'];
        const textMatch = data['text']['matchLevel'];
        let tagMatch = '';
        if (data['tags']?.length) {
          data['tags'].forEach(tag => {
            if (tag['matchLevel'] === 'full') {
              tagMatch = 'full';
            } else if (tag['matchLevel'] === 'partial' && tagMatch !== 'full') {
              tagMatch = 'partial';
            }
          })
        }

        if (titleMatch === 'full' || textMatch === 'full' || tagMatch === 'full')
          matchedFull.push(data);
        else if (titleMatch === 'partial' || textMatch === 'partial' || tagMatch === 'partial')
          matchedPartial.push(data);
      }
    }

    const highlights = hit['_highlightResult'];
    
    if (highlights['contents'] ?? highlights['content'] ?? highlights['body'] ?? highlights['question']) {
      data = {
        title: highlights['question'] ?? highlights['title'],
        text: highlights['contents'] ?? highlights['content'] ?? highlights['body'],
        tags: highlights['tags'],
        relativeDirectory: hit['relativeDirectory'],
        subcategory: hit['subcategory'],
        category: hit['category'],
        slug: hit['slug']
      }
      filter(data);
    }
      
    return { matchedFull, matchedPartial }
  }

  useEffect(() => {
    if (search) {
      client.multipleQueries(queries).then(({ results }) => {
        let fullResults = [];
        let partialResults = [];
        results.forEach(result => {
          result.hits.forEach(hit => {
            const { matchedFull, matchedPartial } = filterAttributes(hit);
            fullResults = fullResults.concat(matchedFull);
            partialResults = partialResults.concat(matchedPartial);
          })
        })
        setResults([...fullResults, ...partialResults])
        setCount(fullResults.length + partialResults.length)
      });
    }
  }, [search]);

  return (
    <SearchBox search={search} setSearch={setSearch} label={label}/>
  )
}