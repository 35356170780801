import React, { useState, useContext, useRef, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Image, ForestryLink } from '../../../../utilities'

import * as styles from './myBtc.module.scss'
import { Context } from '../../../context'

export default ({ activeMenu, setActiveMenu }) => {
  const { site: { siteMetadata: { header: { myBtcLinks } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        header {
          myBtcLinks {
            icon
            text
            url
          }
        }
      }
    }
  }`)

  const ref = useRef(null);

  useEffect(() => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target) && activeMenu && ![
        'Business',
        'Residential',
        'Blog'
      ].includes(activeMenu) && window.innerWidth > 480) {
        setActiveMenu(undefined);
      }
    }

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [activeMenu]);

  const handleLeave = () => {
    setActiveMenu(undefined);
  }

  return (
    <div className={styles.megaMenus} ref={ref}>
  
      <div onMouseDown={handleLeave} className={styles.megaLink + ` ${activeMenu === 'My BTC' ? `${styles.show}` : ""}`}>
      <div className={styles.linkContainer}>
            <div className={styles.link}>
              <span className={styles.userIcon}>
                <Image className={styles.icon} src='/users-dark.svg' container />
              </span>
              My BTC
            </div>
          </div>
        <div className={styles.shadow}></div>
      </div>
      <div className={styles.megaMenu + ` ${activeMenu === 'My BTC' ? `${styles.show}` : ""}`}>
        <div className={styles.left}>
          <h3>My BTC</h3>
          <ul>
            {myBtcLinks.map((link,i) => (
              <li key={i}>
                <a className={styles.link} href={link.url} target='_blank' rel='noreferrer'>
                  <Image className={styles.linkIcon} src={link.icon} />
                  <p>{link.text}</p>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.shadowFirst + ` ${activeMenu === 'My BTC' ? `${styles.show}` : ""}`}></div>
    </div>
  )
}