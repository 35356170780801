// extracted by mini-css-extract-plugin
export var answer = "bD_gM";
export var arrow = "bD_5";
export var body = "bD_c";
export var category = "bD_cq";
export var contactIcons = "bD_gF";
export var current = "bD_d6";
export var fade = "bD_gJ";
export var footer = "bD_cl";
export var footerText = "bD_cm";
export var icon = "bD_br";
export var iconContainer = "bD_b0";
export var issue = "bD_gL";
export var issueContainer = "bD_gH";
export var issueText = "bD_gK";
export var issues = "bD_gG";
export var left = "bD_bH";
export var padding = "bD_dB";
export var right = "bD_bT";
export var section = "bD_j";
export var selectors = "bD_d8";
export var subtitle = "bD_cC";
export var title = "bD_g";