import React, { useEffect, useState, useRef } from 'react'
import * as styles from './mobileMenu.module.scss'
import { Icons, Links, SearchBox, SmallLinks } from './'

export default({ open, activeMenu, setActiveMenu, setOpen }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }

  }, [open])

  return (
    <section className={styles.section + ` ${open ? `${styles.open}` : ""}`}>
      <SearchBox />
      <div className={styles.container} ref={ref}>
        <Links activeMenu={activeMenu} setActiveMenu={setActiveMenu} setOpen={setOpen} />
        <Icons activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
        <SmallLinks setOpen={setOpen} activeMenu={activeMenu} setActiveMenu={setActiveMenu}/>
      </div>
    </section>
  )
}