import React, { useContext } from 'react'
import { Context } from '../../../context'
import { ForestryLink } from '../../../../utilities'
import * as styles from './sharedTextButton.module.scss'

export default ({ button, file = null }) => {
  const context = useContext(Context);
  
  const handleClick = () => {
    if (context && button.type === 'Form') {
      context.setForm(button.form);
      context.setShowForm(true);
    }
  }

  if (!button || !button?.label) {
    return null;
  }

  const fileLink = file || button.file

  return fileLink
  ? (
    fileLink && 
    <button className={styles.button + ` ${button.style === 'Blue fill' ? 
    `${styles.blue}` : button.style === 'Orange fill' ? 
    `${styles.orange}` : button.style === 'White outline' ?
    `${styles.white}`: ""}`}>
      <a className={styles.link} href={fileLink} target='_blank' rel='noopener noreferrer'><p className={styles.label}>{button.label}</p></a>
    </button>
  )
  : (
    button &&
    <button onClick={handleClick} className={styles.button + ` ${button.style === 'Blue fill' ? 
    `${styles.blue}` : button.style === 'Orange fill' ? 
    `${styles.orange}` : button.style === 'White outline' ?
    `${styles.white}`: ""}`}>
      {button.type === 'Page' ? (
        <ForestryLink to={button.page} className={styles.link}>
          <p className={styles.label}>{button.label}</p>
        </ForestryLink>
      ) : button.type === 'Url' ? (
        <a className={styles.link} href={button.url} target='_blank' rel='noopener noreferrer'><p className={styles.label}>{button.label}</p></a>
      ) : (button.type === 'Phone' || button.phone) ? (
        <a className={styles.link} href={`tel:${button.phone}`} target='_blank' rel='noopener noreferrer'><p className={styles.label}>{button.label}</p></a>
      ) : <p className={styles.label}>{button.label}</p>}
    </button>
  )
}