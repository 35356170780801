// extracted by mini-css-extract-plugin
export var buttons = "bq_ck";
export var container = "bq_b";
export var image = "bq_k";
export var innerText = "bq_fj";
export var logo = "bq_bk";
export var p = "bq_dF";
export var section = "bq_j";
export var short = "bq_dG";
export var tall = "bq_fk";
export var textContainer = "bq_bY";