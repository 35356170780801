export const faqHits = {
  "Does BTC Broadband throttle internet speeds?": 2171,
  "How do I check my internet speed?": 2030,
  "What is Lifeline and do I qualify?": 1263,
  "What is fiber-optic technology?": 1045,
  "How can I get the most out of my Gigabit Internet?": 821,
  "Why am I not getting the the speed I pay for?": 781,
  "Can I reset my router myself?": 779,
  "Why did my 5 GHz disappear? I only see 2.4 GHz as a Wi-Fi option now.": 730,
  "What equipment do I need if I have fiber-to-my-home?": 657,
  "If someone in my home or my pet accidently cuts or chews the cable that's lying on top of my yard, who is responsible for paying for the repair?": 603,
  "How do I pay my bill": 587,
  "Why does my cable run across the top of my yard over to my neighbor's yard?": 523,
  "What is SmartHub?": 477,
  "Why is a landline important?": 403,
  "What is fiber-to-the-home or business?": 383,
  "How can I get a better signal on my patio?": 376,
  "What is Gateway Support?": 358,
  "How do I get technical support?": 357,
  "Will I get the same internet speeds wirelessly (Wi-Fi) as wired?": 331,
  "How can I get the speed I pay for over my wireless device?": 303,
  "Why can’t I get a good signal in my bedroom?": 281,
  "Can I change my Wi-Fi password?": 260,
  "How do I change my Voice Mail personal greeting?": 200,
  "What is Gigabit Internet?": 197,
  "Is there any benefit to the value of my home if I have fiber?": 176,
  "What is the difference between download and upload?": 171,
  "What are Wi-Fi dead zones?": 171,
  "How do I check my data usage?": 155,
  "How does BTC Broadband protect user information and will my information be shared with third parties?": 150,
  "Why are my Internet speed tests slower when I am on Wi-Fi?": 125,
  "When is my BTC bill due?": 119,
  "How do I reset my Voice Mail PIN?": 100,
  "What do I do if my service is disconnected for non-payment?": 97,
  "What happens if my account becomes delinquent?": 90,
  "How are services billed?": 85,
  "Can I change the date my BTC bill is due?": 83,
  "What is the status of my repair or trouble ticket?": 81,
  "Can I upgrade from Easy Attendant to Premium Attendant?": 80,
  "If I'm Out of Office, how do I forward my phone to another phone in the office?": 61,
  "What should I do if I know my payment will be late?": 60,
  "Can I make a payment over the phone?": 44,
  "How do I send my Voice Mail messages to my Email?": 37,
  "Why did I not receive my bill or any bill notification?": 32,
  "Why did I receive a past due notice?": 31,
  "How do I set up special holiday/snow menu if I have Easy Attendant": 30,
  "Why doesn’t my payment appear on the invoice?": 18,
  "How do I record my name so people can find me on Easy Attendant dial by name?": 17,
  "How to set up holiday/snow special menu in Premium Attendant": 16
}