import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Image } from '../../../../utilities'
import * as styles from './team.module.scss'

export default ({ block }) => {
  // const [active, setActive] = useState('Executive');
  // const [show, setShow] = useState(false);

  const { allContentJson: { nodes } } = useStaticQuery(graphql`
  {
    allContentJson(filter: 
      {contentTypeTeamMembersSales: {elemMatch: {firstName: {ne: null}, ignore: {ne: true} }},
      contentTypeTeamMembersManagement: {elemMatch: {firstName: {ne: null}, ignore: {ne: true}}}}) {
      nodes {
        contentTypeTeamMembersSales {
          email
          phone
          linkedIn
          firstName
          fullName
          image
          lastName
          title
        }
        contentTypeTeamMembersManagement {
          firstName
          fullName
          image
          lastName
          title
        }
      }
    }
  }`)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShow(true)
  //   }, 100)
  // }, [active])

  const Executive = nodes[0].contentTypeTeamMembersManagement ? nodes[0].contentTypeTeamMembersManagement : null;
  const Sales = nodes[0].contentTypeTeamMembersSales ? nodes[0].contentTypeTeamMembersSales : null;

  const team = block.teamTeam === 'Business' ? Sales : Executive;

  // const handleClick = e => {
  //   setShow(false);
  //   setActive(e.target.innerText.split(' ')[0]);
  // }
  
  return (
    <div className={styles.section} id={block.type}>
      <div className={styles.categories}>
        {/* <button onClick={handleClick} className={styles.category + ` ${active === 'Executive' ? `${styles.active}` : ''}`}>Executive Team</button>
        <button onClick={handleClick} className={styles.category + ` ${active === 'Sales' ? `${styles.active}` : ''}`}>Sales Team</button> */}
        {block.blockTitle}
      </div>
      <h4 className={styles.subtitle}>{block.teamSubtitle}</h4>
      {/* <div className={styles.team + ` ${show ? `${styles.show}` : ''}`}> */}
      <div className={styles.team + ' ' + styles.show}>
        {team && team.map(member => (
          <div key={member.fullName} className={styles.member}>
            {member.image ? (
              <Image src={member.image} className={styles.image} />
            ) : <div className={styles.placeholder}>{member.firstName[0]}{member.lastName[0]}</div>}
            <p className={styles.name}>{member.fullName}</p>
            <p className={styles.memberTitle}>{member.title}</p>
            <div className={styles.icons}>
              {member.phone && <div>
                <a href={`tel:${member.phone}`}><Image src="/phone-outgoing-dark.svg" className={styles.icon} /></a>
              </div>}
              {member.email && <div>
                <a href={`mailto:${member.email}`}><Image src="/mail-dark.svg" className={styles.icon} /></a>
              </div>}
              {member.linkedIn && <div>
                <a href={member.linkedIn} target='_blank' rel='noreferrer'><Image src="/linkedin.svg" className={styles.icon} /></a>
              </div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}