import React, { useState } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './cloudCommunications.module.scss'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

export default ({ block }) => {
 
  return (
    <div className={styles.section} id={block.type}>
      <div className={styles.container}>
        <Image src={block.cloudCommunicationsImage} className={styles.image} />
        <div className={styles.content}>
          <h2 className={styles.title}>{block.blockTitle}</h2>
          <div className={styles.icons}>
            {block.cloudCommunicationsIcons.map((icon, i) => (
              <div key={icon.label} className={styles.iconContainer}>
                <Image src={icon.icon} className={styles.icon} />
                <p className={styles.label}>{icon.label}</p>
              </div>
            ))}
          </div>
          <ReactMarkdown className={styles.text}>
            {block.cloudCommunicationsText}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  )
}