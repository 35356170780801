import React from 'react'
import parse from 'html-react-parser'
import ReactMarkdown from 'react-markdown';

const removeMd = (text: string) => text
  .replace(/\[(.*?)\][\[\(].*?[\]\)]/g, "$1")
  .replace(/([\*_]{1,3})(\S.*?\S{0,1})\1/g, "$2")

export default ({ text }) => {
  if (text && !text.includes('<em>')) {
    if (text.length < 150)
      return <ReactMarkdown>{text}</ReactMarkdown>;
    else {
      for (let i = 150; i < text.length; i++) {
        if (text[i] === ' ')
          return <p>{parse(removeMd(text).slice(0, i + 1) + '...')}</p>;
      }
    }
  }
    
  else if (text) {
    let firstPeriod = null;
    let wordStart = null;
    let wordEnd = null;
    let trimStart = 0;
    let trimEnd = text.length;
    for (let i = 0; i < text.length; i++) {
      if (['.','?','!'].includes(text[i]) && !wordEnd)
        firstPeriod = i + 1;
      if ((text[i] + text[i + 1] + text[i + 2]) === '<em') {
        wordStart = i;
      } else if ((text[i - 2] + text[i - 1] + text[i]) === '/em') {
        wordEnd = i + 1;
      } else if (wordEnd && ['.','?','!'].includes(text[i]) && i - trimStart > 150) {
        trimEnd = i;
        break;
      }
      if (wordStart > 150)
        trimStart = firstPeriod;
    }
    let prefix = trimStart > 0 ? '...' : '';
    let suffix = trimEnd < text.length ? '...' : '';
    const string = prefix + removeMd(text).slice(trimStart, trimEnd) + suffix;
    return <p>{parse(string)}</p>
  }
  return null
}