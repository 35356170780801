import React, { useState, useEffect, useContext } from "react"
import { Image, ForestryLink } from "../../../../utilities"
import * as styles from "./support.module.scss"
import Faq from "./faq/faq"
import Search from "./supportSearch/supportSearch"
import SearchResult from "./supportSearch/searchResult"
import Wave from "react-wavify"
import { Context } from "../../../context"
import SharedTextButton from "../sharedTextButton/sharedTextButton"
import { graphql, useStaticQuery } from "gatsby"

export default ({ block }) => {
  const [faqQuestion, setFaqQuestion] = useState("")
  const [pause, setPause] = useState(true)
  const [amplitude, setAmplitude] = useState(
    typeof window !== "undefined" ? window.innerWidth / 40 : 75
  )
  const [search, setSearch] = useState("")
  const [count, setCount] = useState(0)
  const [results, setResults] = useState([])
  const context = useContext(Context)

  const {
    allFaqCategoriesJson: { nodes: faqCategories },
    allPagesJson: { nodes: allPages },
    allSupportArticlesJson: { nodes: allSupportArticles }
  } = useStaticQuery(graphql`
  {
    allFaqCategoriesJson(filter: {description: {ne: "test"}}) {
      nodes {
        description
        icon
        title
      }
    }
    allPagesJson {
      nodes {
        slug
        title
      }
    }
    allSupportArticlesJson {
      nodes {
        slug
        summary
        issue
      }
    }
  }`)

  const sortedFaqCategories = block.supportFaqCategories.map(c =>
    faqCategories.find(x => x.title === c.subcategory)  
  )

  const [faqCategory, setFaqCategory] = useState(sortedFaqCategories[0]?.title)

  const links = block.supportLinks.map((l, idx) => {
    if (l.page) {
      const page = allPages.find(x => x.slug === l.page)
      return <ForestryLink key={idx} to={page.slug}>
        {page.title}
      </ForestryLink>
    }
  
    const article = allSupportArticles.find(x => x.issue === l.article)

    return (<ForestryLink key={idx} to={`/support/${article.slug}`}>
      {article.summary}
    </ForestryLink>)
  })

  useEffect(() => {
    if (!context.prefersReducedMotion) {
      setPause(false)
      const waveTimer = setTimeout(() => {
        setPause(true)
      }, 3000)

      return () => {
        clearTimeout(waveTimer)
      }
    }
  }, [])

  useEffect(() => {
    const updateAmplitude = () => {
      setTimeout(() => {
        setAmplitude(window.innerWidth / 40)
      }, 1000)
    }

    updateAmplitude()
  }, [context])

  useEffect(() => {
    if (context?.path) {
      const pathSearch = context.path.split('?')[1]
      const searchParams = new URLSearchParams(pathSearch)
      const faqResult = searchParams.get('faq')
      const faqCatResult = searchParams.get('category')
 
      if (faqResult && faqCatResult) {
        setFaqCategory(faqCatResult)

        const parseQ = split => {
          return decodeURIComponent(split)
        }
     
        setFaqQuestion(parseQ(faqResult))
      }
    }
  }, [context && context.path])

  return (
    <div className={styles.section} id={block.type}>
      <div className={styles.top}>
        <div className={styles.wave}>
          <Wave
            fill="url(#gradientSupport)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude,
              speed: 0.2,
              points: 6,
            }}
          >
            <defs>
              <linearGradient
                id="gradientSupport"
                gradientTransform="rotate(60)"
              >
                <stop offset="0%" stopColor="#30BCED" />
                <stop offset="100%" stopColor="#003D79" />
                #003D79
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.contact}>
          {block.supportIcons.map(({ icon }, i) => (
            <a key={i} className={styles.iconContainer} href={icon.linkTo}>
              <Image src={icon.icon} className={styles.icon} />
              <p className={styles.iconText}>{icon.text}</p>
            </a>
          ))}
        </div>
        <Search
          search={search}
          setSearch={setSearch}
          setCount={setCount}
          setResults={setResults}
          label={block.supportSearchLabel}
        />
      </div>
      <SearchResult
        search={search}
        results={results}
        count={count}
        setResults={setResults}
        setSearch={setSearch}
      />
      <h1 className={styles.linksTitle}>{block.supportLinksTitle}</h1>

      <div className={styles.supportLinks}>
        {links}
      </div>

      <h2 className={styles.linksTitle}>{block.supportFaqSectionTitle}</h2>
      <p className={styles.linksSubtitle}>{block.supportFaqSectionSubtitle}</p>
      <div className={styles.faqCategories}>
        {sortedFaqCategories.map((x, idx) => (
          <button
            key={idx}
            className={styles.faqCat + `${faqCategory === x.title ? ` ${styles.selected}` : ""}`}
            onClick={() => setFaqCategory(x.title)}
          >
            <Image src={x.icon} className={styles.faqIcon} />
            <p className={styles.faqCatTitle}>{x.title}</p>
            <p className={styles.faqCatDescription}>{x.description}</p>
          </button>
        ))}
      </div>
      <Faq block={block} faqQuestion={faqQuestion} filter={faqCategory} />
      <div className={styles.bottom}>
        <p className={styles.footerText}>{block.supportFooterText}</p>
        <div className={styles.supportButton}>
          <SharedTextButton button={block.sharedTextButton} />
        </div>
      </div>
    </div>
  )
}
