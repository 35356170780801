import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './bandwidthEstimator.module.scss'
import { Context } from '../../../context'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

export default ({ block }) => {
  const context = useContext(Context);
  const [colorOneWidth, setColorOneWidth] = useState(0);
  const [colorTwoWidth, setColorTwoWidth] = useState(0);
  const [sliderOneCurrent, setSliderOneCurrent] = useState(1);
  const [sliderTwoCurrent, setSliderTwoCurrent] = useState(1);
  const [selectedButton, setSelectedButton] = useState("No");
  const [bandwidth, setBandwidth] = useState(17);
  const ref = useRef(null);
  const sliderTwo = useRef(null);
  const [sliderTwoValue, setSliderTwoValue] = useState(.01);
  const numArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  const subtitle = block.bandwidthEstimatorSubtitle ?? "";
  const subtitleTop = subtitle ? (subtitle.split("?")[0] + "?") : "";
  const subtitleBottom = subtitle.split("?")[1];

  const [showInfo, setShowInfo] = useState(false);
  const infoRef = useCallback(el => {
    if (showInfo && el) {
      const handleClickOutside = event => {
        if (el && !el.contains(event.target)) {
          setShowInfo(false);
          return document.removeEventListener('mousedown', handleClickOutside)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
    }
  }, [showInfo])


  useEffect(() => {
    let speed = 5 + sliderOneCurrent * 4 + (8 * (1 + sliderTwoCurrent - 1));
    if (selectedButton === "Yes")
      speed += 40;
    setBandwidth(speed);
  }, [selectedButton, sliderOneCurrent, sliderTwoCurrent])

  useEffect(() => {
    context.setRecommended(
      bandwidth <= 50 ? 0 :
        (bandwidth > 50 && bandwidth < 90) ? 1 :
          (bandwidth > 90 && bandwidth < 100) ? 2 :
            (bandwidth >= 100) ? 3 : null)
  }, [bandwidth])

  const handleChangeOne = e => {
    setColorOneWidth(e.target.value);
    setSliderOneCurrent(e.target.value < 19 ? Math.ceil(+e.target.value) : Math.floor(+e.target.value));
    if (e.target.value < sliderTwoCurrent) {
      setSliderTwoCurrent(e.target.value < 19 ? Math.ceil(+e.target.value) : Math.floor(+e.target.value))
      setColorTwoWidth(e.target.value);
      setSliderTwoValue(e.target.value)
    }
  }

  const handleChangeTwo = e => {
    if (e.target.value <= sliderOneCurrent) {
      setColorTwoWidth(e.target.value);
      setSliderTwoCurrent(e.target.value < 19 ? Math.ceil(+e.target.value) : Math.floor(+e.target.value));
      setSliderTwoValue(e.target.value)
    }
  }
  const handleButton = e => {
    setSelectedButton(e.target.innerText);
  }

  return (
    <div className={styles.section} id={block.type}>
      <h2 className={styles.title}>{block.blockTitle}</h2>
      <p className={styles.subtitle}>{subtitleTop}</p>
      <p className={styles.subtitle}>{subtitleBottom}</p>
      <div className={styles.container} ref={ref}>
        <div className={styles.left}>
          <div className={styles.group}>
            <Image src={block.bandwidthEstimatorSliderOneIcon} className={styles.icon} />
            <div className={styles.slideContainer}>
              <div className={styles.colorContainer}>
                <div className={styles.color} style={{ width: `${colorOneWidth * 4.75 + 2}%` }}></div>
              </div>
              <label className={styles.label} htmlFor="sliderOne">{block.bandwidthEstimatorSliderOneText}</label>
              <input className={styles.slider} name="slideOne" type="range" min=".1" max="20" step="0.01" defaultValue="0" onChange={handleChangeOne} />
              <div className={styles.numbersContainer}>
                <div className={styles.numbers}>
                  {numArray.map(num => (
                    <div key={num} className={styles.number + " " + ((num === 1 || num === 20 || num === sliderOneCurrent) ? styles.show : styles.hide)}>
                      {num === 20 ? `${num}+` : num}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.group}>
            <Image src={block.bandwidthEstimatorSliderTwoIcon} className={styles.icon} />
            <div className={styles.slideContainer}>
              <div className={styles.colorContainer}>
                <div className={styles.color} style={{ width: `${colorTwoWidth * 4.75 + 2}%` }}></div>
              </div>
              <label className={styles.label} htmlFor="sliderTwo">{block.bandwidthEstimatorSliderTwoText}</label>
              <input className={styles.slider} ref={sliderTwo} name="slideTwo" value={sliderTwoValue} type="range" min=".1" max="20" step="0.01" onChange={handleChangeTwo} />
              <div className={styles.numbersContainer}>
                <div className={styles.numbers}>
                  {numArray.map(num => (
                    <div key={num} className={styles.number + " " + ((num === 1 || num === 20 || num === sliderTwoCurrent) ? styles.show : styles.hide)}>
                      {num === 20 ? `${num}+` : num}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.group}>
            <Image src={block.bandwidthEstimatorYesNoIcon} className={styles.icon} />
            <div className={styles.buttonContainer}>
              <p className={styles.yesNoText}>{block.bandwidthEstimatorYesNoText}</p>
              <div className={styles.buttons}>
                <button onClick={handleButton} className={styles.yes + ` ${selectedButton === "Yes" ? `${styles.selected}` : ""}`}>Yes</button>
                <button onClick={handleButton} className={styles.no + ` ${selectedButton === "No" ? `${styles.selected}` : ""}`}>No</button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <Image src={block.bandwidthEstimatorIcon} className={styles.bandwidthIcon} />
          <p className={styles.bandwidth}>{bandwidth}<small>Mbps</small></p>
          <p className={styles.text}>
            <ReactMarkdown>
              {block.bandwidthEstimatorText}
            </ReactMarkdown>
            <span onMouseDown={() => setShowInfo(!showInfo)} className={styles.info}>ⓘ</span>
          </p>
          <p className={styles.disclaimer + ` ${showInfo ? `${styles.show}` : ''}`} ref={infoRef}>{block.disclaimer && block.disclaimer}</p>
          <a href='#plans'><button onClick={() => context.setShowRecommended(true)} className={styles.button}>{block.bandwidthEstimatorButtonText}</button></a>
        </div>
      </div>
      <h3 className={styles.footerTitle}>{block.bandwidthEstimatorFooterTitle}</h3>
      <p className={styles.footerSubtitle}>{block.bandwidthEstimatorFooterSubtitle}</p>
    </div>
  )
}