import { graphql, useStaticQuery } from "gatsby";

export function useGetNodes() {
  const { allFile: { nodes }} = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {in: "forms"}}) {
        nodes {
          relativePath
          childFormsJson {
            formHeading
            formPages {
              formFields {
                label
                max
                min
                name
                options
                placeholder
                step
                type
                value
              }
            }
            formSubmitToEmail
            formSubheading
            formTitle
            responseText
          }
        }
      }
    }
  `)

  return nodes
}