import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Image, ForestryLink } from '../../../../utilities'

import * as styles from './links.module.scss'
import { Context } from '../../../context'

export default ({ activeMenu, setActiveMenu }) => {
  const url = typeof window !== 'undefined' ? window.location.href.split('/').slice(-2) : '';
  const context = useContext(Context);
  const { site: { siteMetadata: { header: { links, megaMenus } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        header {
          links {
            page
          }
          megaMenus {
            title
            arrow
            arrowDark
          }
        }
      }
    }
  }`)

  return (
    <div className={styles.links + ` ${context && context.scrolled && !activeMenu ? `${styles.scrolled}` : ""}`}>
      {megaMenus.map((menu, i) => (
        <div key={i} onClick={() => setActiveMenu(menu.title)} className={styles.link + ` ${styles.megaMenu}` + ` ${url[0] === menu.title.toLowerCase() ? `${styles.active}` : ""}`}>{menu.title}
          <span className={styles.arrow}>
            <Image className={styles.icon} src={menu.arrow} container />
          </span>
          <span className={styles.arrowDark}>
            <Image className={styles.icon} src={menu.arrowDark} container />
          </span>
        </div>
      ))}
      {links.map((link, i) => (
        <ForestryLink key={i} className={styles.link} activeClassName={styles.active} to={link.page} partiallyActive />
      ))}
    </div>
  )
}