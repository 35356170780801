import React, { useContext } from 'react'
import { Context } from '../../../../context'
import { Trim, Image } from '../../../../../utilities'
import { navigate } from 'gatsby'

import * as styles from './searchResult.module.scss'

export default ({ search, setSearch, results, setResults, count }) => {
  const context = useContext(Context);
  const parseQ = question => {
    return encodeURIComponent(
      question.split('<em>').join('').split('</em>').join('').trim()
    );
  }

  const parsePath = result => {
    const path = result.relativeDirectory;
  
    if (path === 'faq') {
      const subcategory = (result.subcategory ?? '').split(' ')[0]
      return `/support?faq=${parseQ(result.title['value'])}&category=${subcategory}`
    } else if (path === 'support-articles')
      return `/support/${result.slug}`
    else return '/support'
  }

  const parseTemplate = template => {
    return template.split("<em>").join('').split("</em>").join('');
  }

  const handleClose = () => {
    setSearch('');
    setResults([]);
  }

  const handleClick = result => {
    let componentPath = result.type ? `${result.slug}#${parseTemplate(result.type['value'])}` 
    : result.slug ? result.slug 
    : result.relativeDirectory ? parsePath(result) : '';
    context?.setPath(componentPath);
   
    setSearch('');
    setResults([]);
    console.log(result)
    if (result.slug) {
      navigate(parsePath(result))
    }
  }
  return (
    <section className={styles.section + ` ${results.length && search ? `${styles.show}` : ""}`}>
      <div className={styles.logo}>
        <Image src={'/algolia-blue-mark.png'} className={styles.logoIcon}/>
      </div>
      <button className={styles.close} onClick={handleClose}>X</button>
      <h2 className={styles.heading}>found {count} results for '{search}'</h2>
      <div className={styles.container}>
        {results.map((result,i) => (
          <div key={i} className={styles.linkContainer}>
            <button onClick={() => handleClick(result)} className={styles.title} 
            
              >
              <Trim text={result.title['value']} />
            </button>
            <button onClick={() => handleClick(result)} className={styles.value} 
            // to={
            //   result.template ? `${result.slug}#${parseTemplate(result.template['value'])}` : result.slug ? result.slug :
            //   result.relativeDirectory ? parsePath(result) : ''}
              >
                <Trim text={result.text['value']} />
            </button>
          </div>
        ))}
      </div>
    </section>
  )
}