import React, { useRef, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql, Script } from 'gatsby'
import SearchResult from "../../components/header/blocks/search/searchResult/searchResult"
import { Footer, Header, Main } from '../../components'
import { Context } from '../context'
import './reset.scss'
import './global.scss'
import * as styles from './layout.module.scss'


type layoutProps = {
  children?: any,
  title: string,
  blocks?: [any]
  description: string,
  seo_image: string,
  metaTags: MetaTag[]
}

type MetaTag = {
  name: string,
  content: string
}

export default ({ children, title: siteTitle = '', blocks, description, seo_image, metaTags }: layoutProps) => {
  const context = useContext(Context);
  const ref = useRef(null);
  const { site: { siteMetadata: { config: { title, titleDivider } } } } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          config {
            title
            titleDivider
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== 'undefined') {

      const updateScroll = () => {
        context.setScroll(window.scrollY);
        if (window.scrollY > window.innerWidth * .1)
          context.setScrolled(true);
        else context.setScrolled(false);
      }
      
      updateScroll();

      context.setScroll(window.scrollY);

      window.addEventListener('scroll', updateScroll);
      
      return (() => {
        window.removeEventListener('scroll', updateScroll);
      })
    }
  }, [ref])

  return (
    <div className={styles.layout} ref={ref}>
      <Helmet>
        <html lang='en' />
        <title>{siteTitle + titleDivider + `${title || '404: Page Not Found'}`}</title>
        <meta name="facebook-domain-verification" content="0rsal78jxjwbus89m8ofpnh6zgq9wl" />
        <meta name="description" content={description} />
          <meta name="author" content='BTC Broadband' />
          <meta property="og:title" content={siteTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:image" itemProp="image" content={seo_image} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={typeof window !== 'undefined' ? location && location.href : ''} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:image" itemProp="image" content={seo_image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:card" content="summary_large_image" />
        {metaTags && metaTags.map((tag, i) => (
          <meta key={i} name={tag.name} content={tag.content} />
        ))}
        <Script async src="https://identity.netlify.com/v1/netlify-identity-widget.js" strategy='idle'/>
        <Script strategy='idle'>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', 'UA-35639355-1');
          `}
        </Script>
        <Script strategy='idle'>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', 'AW-766740861');
          `}
        </Script>
        <Script strategy='idle'>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', 'GTM-TCJG5GH');
          `}
        </Script>
        <Script async defer strategy='idle'>
          {`
            window.fbAsyncInit = function() {
              FB.init({
                appId            : '497945021591000',
                xfbml            : true,
                version          : 'v20.0'
              });
            };
          `}
        </Script>
        <Script strategy='idle' async defer crossOrigin='anonymous' src='https://connect.facebook.net/en_US/sdk.js'/>
        <Script async defer strategy='idle'>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '497945021591000');
            fbq('track', 'PageView');
          `}
        </Script>
        <Script async defer strategy='idle' id='hs-script-loader' src='js.hs-scripts.com/9335556.js'/>
        <Script strategy='idle'>
          {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2600506,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
          `}
        </Script>
      </Helmet>
      <Header />
      <SearchResult />
      <Main blocks={blocks}>
        {children}
      </Main>
      <Footer />
    </div>
  )
}

