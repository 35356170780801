import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { MegaMenu } from '../'
import { Image, ForestryLink } from '../../../../../utilities'

import * as styles from './links.module.scss'
import { Context } from '../../../../context'

export default ({ activeMenu, setActiveMenu, setOpen }) => {
  
  const { site: { siteMetadata: { mobileMenu: { links, megaMenus } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        mobileMenu {
          links {
            page
            url
            form
            label
          }
          megaMenus {
            title
            arrow
            arrowDark
          }
        }
      }
    }
  }`)
  const context = useContext(Context);

  const handleOpen = title => {
    if (activeMenu === title)
      setActiveMenu('');
    else
      setActiveMenu(title);
  }

  const handleClick = () => {
    setOpen(false)
  }

  const handleFormClick = (form) => {
    context.setForm(form);
    context.setShowForm(true);
    handleClick();
  }

  return (
    <div className={styles.links}>
      {megaMenus.map((menu, i) => (
        <div key={i} onClick={() => handleOpen(menu.title)} className={styles.link + ` ${styles.megaMenu}`}>
          <p className={styles.title}>{menu.title}</p>
          <span className={styles.arrow}>
            <Image className={styles.icon} src={menu.arrow} container />
          </span>
          <span className={styles.arrowDark}>
            <Image onClick={() => handleOpen(menu.title)} className={styles.icon} src={menu.arrowDark} container />
          </span>
          {activeMenu === menu.title &&
          <MegaMenu activeMenu={activeMenu} setActiveMenu={setActiveMenu} setOpen={setOpen} />}
        </div>
      ))}
      {links.map((link, i) => {
        if (link.page) {
          return <ForestryLink key={i} className={styles.otherLink} activeClassName={styles.active} to={link.page} onClick={handleClick}>{link.label}</ForestryLink>
        }
        if (link.form) {
          return <button key={i} className={styles.otherLink} onClick={() => handleFormClick(link.form)}>{link.label}</button>
        }
        if (link.url) {
          return <a className={styles.otherLink} key={i} href={link.url} onClick={handleClick}>{link.label}</a>
        }
      })}
    </div>
  )
}