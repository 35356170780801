import React, { useState, useEffect, useContext, useRef } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './whyFiber.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import { Context } from '../../../context'
import Wave from 'react-wavify'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const [pause, setPause] = useState(true);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 40 : 30)
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);
  const context = useContext(Context);
  const [selected, setSelected] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    const changeWidth = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
        setAmplitude(window.innerWidth / 40)
      }, 1000)
    }
    changeWidth();
  }, [context])

  useEffect(() => {
    if (!context.prefersReducedMotion && ref.current && ref.current.getBoundingClientRect().top < (context.scroll + window.innerHeight) && ref.current.getBoundingClientRect().bottom > 0) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
  }, [context])

  const sectionStyle = block.negative_top_margin
    ? [styles.section, styles.negativeTop].join(" ")
    :  styles.section
  console.log(block)
  return (
    <div className={sectionStyle} id={block.type}>
      <div className={styles.container}>
        <div className={styles.backgroundContainer} ref={ref}>
          <div className={styles.middleWave}>
            <Wave fill="#0958a7"
              paused={pause}
              options={{
                height: 100,
                amplitude: amplitude * 1.8,
                speed: .2,
                points: 5
              }}
            />
          </div>
          <div className={styles.bottomWave}>
            <Wave fill="#0958a7"
              paused={pause}
              options={{
                height: 100,
                amplitude: amplitude * 3,
                speed: .2,
                points: 6
              }}
            />
          </div>
        </div>
        <svg
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            x='0px' y='0px'
            viewBox='0 0 1600 1000'
            xmlSpace='preserve'
            className={styles.background}
            preserveAspectRatio="xMidYMin meet"
          >
          <defs>
            <linearGradient id='why-fiber-gradient'>
              <stop offset='0%' stopColor='rgba(0,0,0,0)'/>
              <stop offset='100%' stopColor='rgba(0, 61, 121, 1)'/>
            </linearGradient>
            <clipPath id='clip'>
              <path
                d='M 0 3000 L 0 70 C 533 0, 533 0, 1039 100 C 1066 100, 1320 200, 1600 50 L 1600 3000'
                fill='#000000'
              />
            </clipPath>
            
          </defs>
          <image href={block.whyFiberImage} y="0" x="0" clipPath='url(#clip)' />
          <path
            d='M 0 3000 L 0 70 C 533 0, 533 0, 1039 100 C 1066 100, 1320 200, 1600 50 L 1600 3000'
            fill='url(#why-fiber-gradient)'
          />
        </svg>
        <div className={styles.content}>
          <h2 className={styles.title}>{block.blockTitle}</h2>
          <div className={styles.iconContainer}>
            {block.whyFiberTabs.map((tab, i) => (
              <button
                onClick={() => setSelected(i)}
                onMouseOver={() => setSelected(i)}
                key={`${tab.icon}${i}`}
                className={selected === i ? ` ${styles.selected}` : ""}>
                <Image src={tab.icon} className={styles.icon} />
              </button>
            ))}
          </div>
          <div className={styles.tabText}>
            {block.whyFiberTabs.map((tab, i) => (
              <div key={i} className={styles.tab + ` ${selected === i ? `${styles.selected}` : ""}`}>
                <h2>{tab.title}</h2>
                <ReactMarkdown>{tab.text}</ReactMarkdown>
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            {block.whyFiberButtons.map(({ sharedTextButton }, i) => (
              <SharedTextButton key={i} button={sharedTextButton} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}