import React, { useState, useEffect, useContext, useRef } from 'react'
import * as styles from './scholarshipBanner.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import { Context } from '../../../context'
import Wave from 'react-wavify'

export default ({ block }) => {
  const [pause, setPause] = useState(true);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 60 : 30)
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);
  const context = useContext(Context);
  const [selected, setSelected] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const changeWidth = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
        setAmplitude(window.innerWidth / 60)
      }, 1000)
    }
    changeWidth();
  }, [context])
  
  useEffect(() => {
    if (!context.prefersReducedMotion && ref.current && ref.current.getBoundingClientRect().top < (context.scroll + window.innerHeight) && ref.current.getBoundingClientRect().bottom > 0) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
  }, [context])
  

  return (
    <div className={styles.section} id={block.type}>
      <div className={styles.container} ref={ref}>
        <div className={styles.middleWave}>
          <Wave fill="url(#gradient5)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude * 1.5,
              speed: .2,
              points: 4
            }}
          >
            <defs>
              <linearGradient id="gradient5" gradientTransform="rotate(0)">
                <stop offset="0%"  stopColor="#FEC902" />
                <stop offset="70%" stopColor="#E6332F" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.bottomWave}>
          <Wave fill="url(#gradient6)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude * 1.5,
              speed: .2,
              points: 3
            }}
          >
            <defs>
              <linearGradient id="gradient6" gradientTransform="rotate(0)">
                <stop offset="0%"  stopColor="#FEC902" />
                <stop offset="70%" stopColor="#E6332F" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{block.blockTitle}</h2>
          <p className={styles.subtitle}>{block.bannerSubtitle}</p>
          <div className={styles.buttonContainer}>
          {block.buttons && block.buttons.map((button, i) =>
            <div className={styles.button}>
              <SharedTextButton button={button.sharedTextButton}  />
              { button.buttonFooter && 
                <p className={styles.buttonFooter}>
                  {button.buttonFooter}
                </p>
              }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}