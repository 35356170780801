import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as styles from './supportVideos.module.scss'
import { graphql, useStaticQuery } from 'gatsby'
import ReactPlayer from 'react-player'
import Image from '../../../../utilities/image'

export default ({ block }) => {
  const {
    allSupportVideosJson: { nodes: unFilteredVideos },
    allSupportVideoCategoriesJson: { nodes: categories },
  } = useStaticQuery(graphql`
    {
      allSupportVideosJson(filter: {title: {ne: "_test"}}) {
        nodes {
          title
          videoURl
          thumbnail
        }
      }
      allSupportVideoCategoriesJson(filter: {category: {ne: "_test"}}) {
        nodes {
          category
          featured
          videos {
            video
          }
        }
      }
    }
  `)

  const videoCategories = block.supportVideoCategories.map(x =>
    categories.find(c => c.category === x.supportVideoCategory)  
  )
  const [active, setActive] = useState(videoCategories[0]?.category)

  const [filter, setFilter] = useState('')

  const setFeaturedVideo = () => unFilteredVideos.find(
    x => categories.find(c => c.category === active)?.featured === x.title
  ) ?? { title: '', videoURl: '', thumbnail: '/placeholder.png' }

  const [featured, setFeatured] = useState(setFeaturedVideo)

  const applyFilter = (x: string) =>
    (!filter || x.toLowerCase().includes(filter.toLowerCase()))

  const videos = unFilteredVideos.filter(x => applyFilter(x.title))

  const selectedCategory = videoCategories.find(c => c.category === active)
    ?? { videos: [], category: '' }

  const selectedVideos = useMemo(() => selectedCategory.videos
    .filter(x => applyFilter(x.video)), [selectedCategory, featured, filter])

  const slider = useRef<HTMLDivElement>(null);
  const mobileSlider = useRef<HTMLDivElement>(null);

  const [maxCurrent, setMaxCurrent] = useState(Math.ceil(selectedVideos.length / 3) - 1)

  const [current, setCurrent] = useState(0)

  const calculateFillerCount = () => {
    const perPage = 3
    if (perPage >= selectedVideos.length) {
      return 0
    }

    const remainder = selectedVideos.length % perPage
    return perPage - remainder
  }

  const [fillerCount, setFillerCount] = useState(calculateFillerCount)

  const canShowButtons = maxCurrent > 0

  const getSlider = () => window.innerWidth > 480 ? slider?.current : mobileSlider?.current

  const resetScroller = () => {
    setCurrent(0)
    const s = getSlider()
    if (s) {
      s.style.scrollBehavior = 'auto'
      s.scrollLeft = 0;
      s.style.scrollBehavior = 'smooth'
    }
  }

  useEffect(() => {
    const adjustMaxCurrent = () => {
      if (window.innerWidth > 480) {
        setMaxCurrent(Math.ceil(selectedVideos.length / 3) - 1)
      }
      else {
        setMaxCurrent(Math.ceil(selectedVideos.length / 2) - 1)
      }

      resetScroller()
      setFillerCount(calculateFillerCount)
    };

    window.addEventListener('resize', adjustMaxCurrent)

    adjustMaxCurrent()

    return (() => {
      window.removeEventListener('resize', adjustMaxCurrent)
    })
  }, [selectedVideos]);

  useEffect(() => {
    setFeatured(setFeaturedVideo)
  }, [selectedCategory])

  const scrollRight = () => {
    const s = getSlider()
    if (s && current != maxCurrent) {
      setCurrent(current + 1)
      s.scrollLeft += s.offsetWidth;
    }
  }
  
  const scrollLeft = () => {
    const s = getSlider()
    if (s && current != 0) {
      setCurrent(current - 1)
      s.scrollLeft -= s.offsetWidth;
    }
  }

  const handleSelectorClick = (index: number) => {
    const s = getSlider()
    if (s) {
      s.scrollLeft = s.offsetWidth * index;
      setCurrent(index)
    }
  }

  const mobileVideos: any[][] = [[]]
  selectedVideos.forEach(v => {
    const last = mobileVideos[mobileVideos.length - 1]
    if (last.length === 2) {
      mobileVideos.push([v])
    } else {
      last.push(v)
    }
  })
 
  return (
    <section className={styles.section}>
      <h1>{block.blockTitle}</h1>
      <p className={styles.description}>{block.description}</p>

      <div className={styles.categories}>
        {videoCategories.map((x,i) => (
          <button
            key={i}
            className={styles.category + ` ${x.category === active ? styles.active : ""}`}
            onClick={() => setActive(x.category)}
          >
            {x.category}
          </button>
        ))}
      </div>

      <div className={styles.featured}>
        <div className={styles.videoContainer}>
          <p className={styles.sectionTitle}>{featured?.title}</p>
          <div className={styles.video}>
            <ReactPlayer
              width="100%"
              height="100%"
              url={featured?.videoURl}
              controls
              light={featured?.thumbnail
              } 
            />
          </div>
        </div>
      </div>

      <div className={styles.moreVideosHeader}>
        <div />
        <p className={styles.sectionTitle}>{block.moreVideosLabel}</p>
        <div className={styles.search}>
          <input className={styles.input} onChange={e => setFilter(e.target.value)} />
          <Image className={styles.icon} src={'/search-gray.svg'} container />
        </div>
      </div>
      
      <div className={styles.moreVideos}>
        <div>
          {current != 0 && canShowButtons && <button className={styles.scrollButton} onClick={scrollLeft}>
            <Image className={styles.scrollArrow + ` ${styles.scrollLeft}`} src={'/caret-right.svg'} container />
          </button>}
        </div>

        <div className={styles.moreVideosSlider} ref={slider}>
          {selectedVideos.map(({ video }, i) => (
            <div className={styles.moreVideoContainer} key={i}>
              <p className={styles.moreVideoTitle}>{videos.find(x => x.title === video)?.title}</p>
              <button
                className={styles.moreVideo}
                onClick={() => setFeatured(
                  videos.find(x => x.title === video)
                )}>
                <Image className={styles.moreVideoThumbnail} src={videos.find(x => x.title === video)?.thumbnail} container />
              </button>
            </div>
          ))}
          {fillerCount > 0 && [...new Array(fillerCount).keys()].map((_,i) => (
            <div className={styles.moreVideoContainer} key={i + 'a'}/>
          ))}
        </div>

        <div className={styles.moreVideosSliderMobile} ref={mobileSlider}>
          {mobileVideos.map((vidArray, i) => (
            <div className={styles.mobileVidsContainer} key={i}>
              {vidArray.map(({ video }, k) => (
                <div className={styles.mobileVidContainer} key={`${i}${k}`}>
                  <p className={styles.mobileVideoTitle}>{videos.find(x => x.title === video)?.title}</p>
                  <button
                    className={styles.mobileVideo}
                    onClick={() => setFeatured(
                      videos.find(x => x.title === video)
                    )}>
                    <Image className={styles.mobileVideoThumbnail} src={videos.find(x => x.title === video)?.thumbnail} container />
                  </button>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div>
          {current != maxCurrent && canShowButtons && <button className={styles.scrollButton + ` ${styles.scrollRight}`} onClick={scrollRight}>
            <Image className={styles.scrollArrow + ` ${styles.scrollRight}`} src={'/caret-right.svg'} container />
          </button>}
        </div>
      </div>

      <div className={styles.selectors}>
        {canShowButtons && [...new Array(maxCurrent + 1).keys()].map(i => (
          <button
            key={i}
            className={styles.selector + ` ${current === i ? styles.active : ""}`}
            onClick={() => handleSelectorClick(i)}
          />
        ))}
      </div>
    </section>
  )
}