// extracted by mini-css-extract-plugin
export var active = "by_8";
export var categories = "by_cp";
export var category = "by_cq";
export var icon = "by_br";
export var icons = "by_bs";
export var image = "by_k";
export var member = "by_fW";
export var memberTitle = "by_fX";
export var name = "by_ft";
export var placeholder = "by_6";
export var section = "by_j";
export var show = "by_bj";
export var subtitle = "by_cC";
export var team = "by_fV";