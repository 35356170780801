import React, { useState, useEffect, useContext, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Image } from '../../../../utilities'
import * as styles from './residentialVoice.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import { Context } from '../../../context'
import ReactMarkdown from 'react-markdown'
import Wave from 'react-wavify'

export default ({ block }) => {
  const [pause, setPause] = useState(true);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 80 : 30)
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);
  const context = useContext(Context);
  const ref = useRef(null);
  const { allPagesJson: { edges } } = useStaticQuery(graphql`
  {
    allPagesJson(filter: {slug: {eq: "/residential/voice"}}) {
      edges {
        node {
          blocks {
            residentialVoiceImage
          }
        }
      }
    }
  }`)

  const bottomImage = edges[0].node.blocks.filter(({whyFiberImage}) => whyFiberImage !== null )[0];
  
  useEffect(() => {
    const changeWidth = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
        setAmplitude(window.innerWidth / 80)
      }, 1000)
    }
    changeWidth();
  }, [context])
  
  useEffect(() => {
    if (!context.prefersReducedMotion && ref.current && ref.current.getBoundingClientRect().top < (context.scroll + window.innerHeight) && ref.current.getBoundingClientRect().bottom > 0) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
  }, [context])

  return (
    <div className={styles.section} id={block.type}>
      <div className={styles.container} ref={ref}>
        <div className={styles.backgroundContainer}>
          <Image src={block.residentialVoiceImage} className={styles.background} container/>
        </div>
        <div className={styles.middleWave}>
          <Wave fill="#787878"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude * 1.8,
              speed: .2,
              points: 7
            }}
          />
        </div>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{block.blockTitle}</h2>
          <ReactMarkdown className={styles.text}>{block.residentialVoiceBody}</ReactMarkdown>
        </div>
      </div>
    </div>
  )
}