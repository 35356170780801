import React from 'react'
import { Image } from '../../../../utilities'
import * as styles from './careers.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'

export default ({ block }) => {


  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {block.careers.map((career, i) => 
          <div key={`${i}${career.careersTitle}`} className={styles.careerContainer}>
            <div className={styles.career}>
              <h4 className={styles.title}>{career.careersTitle}</h4>
              <p className={styles.location}>{career.careersLocation}</p>
              <a className={styles.link} target='_blank' rel='noreferrer' href={career.careersUrl}>
                APPLY
                <span>
                  <Image src={'/arrow-right.svg'} className={styles.arrow} />
                </span>
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}