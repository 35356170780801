import React from 'react'
import * as styles from './legal.module.scss'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {

  return (
    <section className={styles.section}>
      <h1 className={styles.heading}>{block.heading}</h1>
      <span id="tariffs" />
      <div className={styles.documents}>
        <h4>{block.documentsHeading}</h4>
        {block.documents.map(document => (
          <a key={document.label} className={styles.document} href={document.document} target='__blank' rel='noreferrer'>{document.label}</a>
        ))}
      </div>
      <div className={styles.tariffs}>
        <h4>{block.tariffsHeading}</h4>
        {block.tariffItems.map((item, i) => (
          <div key={i} className={styles.tariff}>
            <ReactMarkdown>{item.tariffItem}</ReactMarkdown>
          </div>
        ))}
      </div>
    </section>
  )
}