import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './cloudPlans.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import Wave from 'react-wavify';
import { Context } from '../../../context'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const arrowLeft = 'arrow-left.svg';
  const arrowRight = 'arrow-right.svg';
  const [current, setCurrent] = useState(0);
  const [pause, setPause] = useState(true);
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 30 : 30)
  const context = useContext(Context);
  const ref = useRef(null);
  const slider = useRef(null);
  const style = typeof window !== 'undefined' ? document.documentElement.style : null;
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const changeWidth = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
        setAmplitude(window.innerWidth / 30)
      }, 1000)
    }
    changeWidth();
    if (slider.current)
      slider.current.scrollLeft = 0;
    setCurrent(0);

  }, [context && context.width])

  useEffect(() => {
    if (!context.prefersReducedMotion && ref.current && ref.current.getBoundingClientRect().top < (context.scroll + window.innerHeight) && ref.current.getBoundingClientRect().bottom > 0) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
  }, [context])

  const moveRight = () => {
    if (slider.current) {
      const slide = slider.current;
      const distance = window.innerWidth * .723;
      slide.scrollLeft = slide.scrollLeft + distance;
      setCurrent(current + 1)
    }
  }

  const moveLeft = () => {
    if (slider.current) {
      const slide = slider.current;
      const distance = window.innerWidth * .723;
      slide.scrollLeft = slide.scrollLeft - distance;
      setCurrent(current - 1)
    }
  }

  const infoRef = useCallback(el => {
    if (showInfo && el) {
      const handleClickOutside = event => {
        if (el && !el.contains(event.target)) {
          setShowInfo(false);
          return document.removeEventListener('mousedown', handleClickOutside)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
    }
  }, [showInfo])

  return (
    <div className={styles.section} ref={ref} id={block.type}>
      <div className={styles.waves}>
        <div className={styles.waveOne}>
          <Wave fill="url(#gradient)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude * .9,
              speed: .35,
              points: 3
            }}
          >
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(65)">
                <stop offset="0%" stopColor="#1397c7" />
                <stop offset="50%" stopColor="#003D79" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.waveTwo}>
          <Wave fill="url(#gradient2)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude * .8,
              speed: .2,
              points: 6
            }}
          >
            <defs>
              <linearGradient id="gradient2" gradientTransform="rotate(50)">
                <stop offset="-20%" stopColor="#1397c7" />
                <stop offset="70%" stopColor="#033c75" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.waveThree}>
          <Wave fill="url(#gradient3)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude,
              speed: .2,
              points: 7
            }}
          >
            <defs>
              <linearGradient id="gradient3" gradientTransform="rotate(60)">
                <stop offset="0%" stopColor="#1397c7" />
                <stop offset="70%" stopColor="#003D79" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
      </div>
      <div className={styles.container}>
        <h2 className={styles.heading}>{block.blockTitle && block.blockTitle}</h2>
        <p className={styles.subHeading}>{block.cloudPlansSubHeading && block.cloudPlansSubHeading}
          <span onMouseDown={() => setShowInfo(!showInfo)} className={styles.info}>ⓘ</span>
        </p>
        <p className={styles.description + ` ${showInfo ? `${styles.show}` : ''}`} ref={infoRef}>{block.cloudPlansDescription && 
          <ReactMarkdown>
            {block.cloudPlansDescription}
          </ReactMarkdown>}</p>
        <div className={styles.columns}>
          <div className={styles.options}>
            {block.cloudPlansOptions && block.cloudPlansOptions.map(option => (
              <div key={option.option} className={styles.option}>
                <div className={styles.optionText}>{option.option}</div>
                <div className={styles.border}></div>
              </div>
            ))}
          </div>
          <div className={styles.plans} ref={slider}>
            {block.cloudPlansPlans && block.cloudPlansPlans.map((plan, i) => (
              <div key={i} className={styles.column + ` ${current === i ? `${styles.active}` : ""}`}>
                {i !== block.cloudPlansOptions.length - 1 &&
                  <button className={styles.left + ` ${current !== i ? `${styles.show}` : ""}`} onClick={moveLeft}>
                    <Image src={arrowLeft} className={styles.arrow} />
                  </button>}
                {i !== 0 &&
                  <button className={styles.right + ` ${current !== i ? `${styles.show}` : ""}`} onClick={moveRight}>
                    <Image src={arrowRight} className={styles.arrow} />
                  </button>}
                <div className={styles.title}>{plan.title && plan.title}</div>
                {block.cloudPlansOptions && block.cloudPlansOptions.map((option, i) => (
                  <div key={i} className={styles.includes}>
                    <p className={styles.mobileOption}>{option.option}</p>
                    {option.plans.includes(plan.title) ? (
                      <Image src={block.cloudPlansIcon} className={styles.icon} />
                    ) : <div className={styles.dot}></div>}
                  </div>
                ))}
                {plan.price &&
                  <div className={styles.price}>
                    <p>{plan.price}
                      {plan.priceSuffix &&
                        <span className={styles.suffix}>
                          {plan.priceSuffix}
                        </span>}
                    </p>
                  </div>}
                {plan.sharedTextButton &&
                  <div className={styles.button}>
                    <SharedTextButton button={plan.sharedTextButton} />
                  </div>}
              </div>
            ))}
            <div className={styles.padding}></div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerText}>{block.cloudPlansFooterText &&
            <ReactMarkdown>{block.footerText}</ReactMarkdown>}
          </div>
          <div className={styles.button}>{block.sharedTextButton &&
            <SharedTextButton button={block.sharedTextButton} />}
          </div>
          <div className={styles.subText}>{block.footerSubtext &&
            <p>{block.footerSubtext}</p>}
          </div>
        </div>
      </div>
    </div >
  )
}
