import React, { useState, useRef, useEffect, useContext, useCallback } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './featureColumns.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import Wave from 'react-wavify';
import { Context } from '../../../context'
import ReactMarkdown from 'react-markdown'
import ParseSuperscript from './parseSuperscript'

export default ({ block }) => {
  const arrowLeft = 'arrow-left.svg';
  const arrowRight = 'arrow-right.svg';
  const [current, setCurrent] = useState(0);
  const [pause, setPause] = useState(true);
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 30 : 30)
  const [recommendedPlan, setRecommendedPlan] = useState(null);
  const context = useContext(Context);
  const [disclaimer, setDisclaimer] = useState("");
  const ref = useRef(null);
  const slider = useRef(null);
  const style = typeof window !== 'undefined' ? document.documentElement.style : null;
  const parsedUrl = typeof window !== 'undefined' ? window.location.href.split("/") : null;
  const url = typeof window !== 'undefined' ? window.location.href.split('/').slice(-2).join('/') : '';
  const urlCheck = 'residential/internet#plans';

  if (block.featureColumnsFooter && style && block.featureColumnsFooter.length > 200)
    style.setProperty('--wave-height', '105vw');
  else if (style)
    style.setProperty('--wave-height', '86vw');

  useEffect(() => {
    if (url.includes('residential/internet') && context.recommended >= 0 && slider.current &&  typeof window !== 'undefined' && window.innerWidth <= 480) {
      setCurrent(context.recommended);
      slider.current.scrollLeft = context.recommended * (window.innerWidth * .723);
    }
  }, [context && context.recommended])

  useEffect(() => {
    const changeWidth = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
        setAmplitude(window.innerWidth / 30)
      }, 1000)
    }
    changeWidth();
    if (slider.current && context.width && !context.showRecommended) {
      slider.current.scrollLeft = 0;
      if (current > 0 && !context.recommended)
        setCurrent(0);
    }  
  }, [context && context.width])

  useEffect(() => {
    if (!context.prefersReducedMotion && ref.current && ref.current.getBoundingClientRect().top < (context.scroll + window.innerHeight) && ref.current.getBoundingClientRect().bottom > 0) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
  }, [context && context.scroll])

  const moveRight = () => {
    if (slider.current) {
      const slide = slider.current;
      const distance = window.innerWidth * .723;
      slide.scrollLeft = slide.scrollLeft + distance;
      setCurrent(current + 1)
    }
  }

  const moveLeft = () => {
    if (slider.current) {
      const slide = slider.current;
      const distance = window.innerWidth * .723;
      slide.scrollLeft = slide.scrollLeft - distance;
      setCurrent(current - 1)
    }
  }

  const disclaimerRef = useCallback(el => {
    if (disclaimer && el) {
      const handleClickOutside = event => {
        if (el && !el.contains(event.target)) {
            setDisclaimer("");
            return document.removeEventListener('mousedown', handleClickOutside)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
    }
  }, [disclaimer])

  return (
    <div className={styles.section} ref={ref} id={block.type}>
      <div className={styles.waveContainer}>
        <div className={styles.waves}>
          <div className={styles.waveOne}>
            <Wave fill="url(#gradient)"
              paused={pause}
              options={{
                height: 100,
                amplitude: amplitude,
                speed: .2,
                points: 3
              }}
            >
              <defs>
                <linearGradient id="gradient" gradientTransform="rotate(30)">
                  <stop offset="0%"  stopColor="#1397c7" />
                  <stop offset="70%" stopColor="#003D79" />
                </linearGradient>
              </defs>
            </Wave>
          </div>
          <div className={styles.waveTwo}>
            <Wave fill="url(#gradient2)"
              paused={pause}
              options={{
                height: 100,
                amplitude: amplitude * .8,
                speed: .2,
                points: 6
              }}
            >
              <defs>
                <linearGradient id="gradient2" gradientTransform="rotate(50)">
                  <stop offset="-20%"  stopColor="#1397c7" />
                  <stop offset="70%" stopColor="#033c75" />
                </linearGradient>
              </defs>
            </Wave>
          </div>
          <div className={styles.waveThree}>
            <Wave fill="url(#gradient3)"
              paused={pause}
              options={{
                height: 100,
                amplitude: amplitude,
                speed: .2,
                points: 7
              }}
            >
              <defs>
                <linearGradient id="gradient3" gradientTransform="rotate(60)">
                  <stop offset="0%"  stopColor="#1397c7" />
                  <stop offset="70%" stopColor="#003D79" />
                </linearGradient>
              </defs>
            </Wave>
          </div>
        </div>
      </div>
      
      <div className={styles.container} id="plans">
        <h2 className={styles.heading}>
          <ReactMarkdown>{block.blockTitle}</ReactMarkdown>
        </h2>
        {block.featureColumnsSubheading &&
        <ReactMarkdown className={styles.subHeading}>{block.featureColumnsSubheading}</ReactMarkdown>}
        <div className={styles.columns} ref={slider}>
          {block.featureColumnsColumns.map((feature, i) => {
            return (
            <div key={feature.title} className={styles.column + ` ${current === i ? `${styles.active}` : ""}` +
            ` ${context?.showRecommended && context.recommended === i ? `${styles.recommend}` : ""}`}>
              {i !== block.featureColumnsColumns.length - 1 &&
              <button className={styles.left + ` ${current !== i ? `${styles.show}` : ""}`} onClick={moveLeft}>
                <Image src={arrowLeft} className={styles.arrow} />
              </button>}
              {i !== 0 &&
              <button className={styles.right + ` ${current !== i ? `${styles.show}` : ""}`} onClick={moveRight}>
                <Image src={arrowRight} className={styles.arrow} />
              </button>}
              {feature.popularTab && 
                <div className={styles.popular}>Most popular</div>}
              <div className={styles.title + ` ${url === urlCheck && context && context.showRecommended && context.recommended === i ? `${styles.recommended}`: ""}`}>{feature.title && <ParseSuperscript title={feature.title} />}</div>
              <div className={styles.iconContainer}>
                <Image src={feature.icon} className={styles.icon} />
              </div>
              <div className={styles.description}>
                <ReactMarkdown>{feature.description}</ReactMarkdown>
                {feature.highlightPrefix && 
                <div className={styles.prefix}>
                  <ReactMarkdown>{feature.highlightPrefix}</ReactMarkdown>
                </div>}
                {feature.disclaimer &&
                  <button onClick={() => { 
                    setDisclaimer(disclaimer ? "" : feature.disclaimer)
                  }} className={styles.disclaimerButton}>ⓘ</button>}
              </div>
              {feature.highlight &&
                <div className={styles.highlight}>
                  <p>{feature.highlight}
                  {feature.highlightSuffix && feature.highlightSuffix.length <= 4 &&
                    <span className={styles.suffix}>
                      {feature.highlightSuffix}
                    </span>}
                  </p>
                  {feature.highlightSuffix && feature.highlightSuffix.length > 4 &&
                    <p className={styles.suffix}>{feature.highlightSuffix}</p>}
                </div>}
                {feature.sharedTextButton &&
                  <div className={styles.button + ` ${url === urlCheck && context && context.showRecommended && context.recommended === i ? `${styles.recommended}` : ""}`}>
                  {feature.sharedTextButton ? <SharedTextButton button={feature.sharedTextButton} /> : null}
                </div>}
              
              {feature.footer &&
                <p className={styles.featureFooter}>{feature.footer}</p>}
            </div>
          )
          })}
          <div className={styles.padding}></div>
        </div>
        <div className={styles.footer}>{block.featureColumnsFooter && 
          <ReactMarkdown>{block.featureColumnsFooter}</ReactMarkdown>}
        </div>
        <div className={styles.disclaimer + ` ${disclaimer ? `${styles.show}` : ''}`}ref={disclaimerRef}>{disclaimer && 
          <div className={styles.disclaimerContainer}>
          <div className={styles.fadeTop} />
          <ReactMarkdown className={styles.disclaimerText}>{disclaimer}</ReactMarkdown>
          <div className={styles.fadeBottom} />
          </div>}
        </div>
        {block.sharedTextButton?.label &&
        <div className={styles.button}>
          <SharedTextButton button={block.sharedTextButton} />
        </div>}
      </div>
      <div className={styles.footerMobile}>{block.featureColumnsFooter && 
        <ReactMarkdown>{block.featureColumnsFooter}</ReactMarkdown>}
      </div>
      {block.sharedTextButton?.label &&
      <div className={styles.buttonMobile}>
        <SharedTextButton button={block.sharedTextButton} />
      </div>}
    </div>
  )
}