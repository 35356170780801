import { functions, isEqual, omit } from 'lodash'
import React, { useState, useEffect, useRef } from 'react'

const Map = ({ coordinates, options, onMount, className, onMountProps }) => {
  const ref = useRef()
  const [map, setMap] = useState()

  useEffect(() => {
    const onLoad = () =>
      setMap(new window.google.maps.Map(ref.current, { ...options }))

    if (!window.google) {
      const script = document.createElement(`script`)
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=` +
        process.env.GATSBY_GOOGLE_MAPS_API
      document.head.append(script)
      script.addEventListener(`load`, onLoad)
      return () => script.removeEventListener(`load`, onLoad)
    }

  }, [options, coordinates])

  useEffect(() => {
    if (window.google) {
      const map = new window.google.maps.Map(ref.current, { ...options })
      setMap(map)
    }
  }, [options, onMountProps])

  if (map && typeof onMount === `function`) onMount(map, onMountProps)
  return (
    <div
      {...{ ref, className }}
    />
  )
}
const shouldNotUpdate = (props, nextProps) => {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)]
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs))
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every(fn => props[fn].toString() === nextProps[fn].toString())
  return noPropChange && noFuncChange
}

export default React.memo(Map, shouldNotUpdate)