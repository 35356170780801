import React from 'react'
import Form from '../../../../utilities/sharedForm/components/form'
import * as styles from './pageForm.module.scss'

export default ({ block }) => {
  return <section className={styles.section}>
    <Form {...{
      showForm: true,
      formContext: block.form,
      onClose: undefined
    }} />
  </section>
}