import { functions, isEqual, omit } from 'lodash'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Context } from '../../../context'

const Map = ({ options, className, onMountProps }) => {
  const context = useContext(Context)
  const loader = context?.loader

  const ref = useRef()
  const [map, setMap] = useState()

  const urls = [
    'https://www.google.com/maps/d/u/2/kml?mid=1YXDF0EypoPm8CsgB1cRzPWszhWvW7jH0',
    'https://www.google.com/maps/d/u/1/kml?mid=1gPhd74L3C72CtzQHFVNsoF5qoGSk2Dxx',
  ]

  const addKml = (map) => {
    if (typeof window !== 'undefined') {
      urls.forEach((url, i) => {
        const kmlLayer = new window.google.maps.KmlLayer({
          url: url,
          suppressInfoWindows: false,
          preserveViewport: true,
          zIndex: 2,
          map
        });
      })
    }
  }

  useEffect(() => {
    loader.load().then((google) => {
      setMap(new google.maps.Map(ref.current, { ...options }))
    })
  }, [options, onMountProps, loader])

  useEffect(() => {
    if (map) addKml(map)
  }, [map])

  return (
    <div
      {...{ ref, className }}
    />
  )
}
const shouldNotUpdate = (props, nextProps) => {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)]
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs))
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every(fn => props[fn].toString() === nextProps[fn].toString())
  return noPropChange && noFuncChange
}

export default React.memo(Map, shouldNotUpdate)