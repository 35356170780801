// extracted by mini-css-extract-plugin
export var background = "bt_z";
export var backgroundContainer = "bt_fh";
export var bottomImage = "bt_t";
export var bottomImageContainer = "bt_fs";
export var buttons = "bt_ck";
export var container = "bt_b";
export var imagesContainer = "bt_fr";
export var middleWave = "bt_cW";
export var section = "bt_j";
export var text = "bt_cg";
export var textContainer = "bt_bY";
export var title = "bt_g";
export var topImage = "bt_m";
export var waveOverlay = "bt_fq";