import React from 'react'

export default ({ styles, field, getStyle, formValues, handleInputChange }) => (
  <>
    <label className={styles.textLabel} htmlFor={field.label}>{field.label}</label>
    <textarea 
      className={styles.textInput + ` ${getStyle(field.label)}`} 
      id={field.label} 
      name={field.label} 
      value={formValues[field.label]}
      onChange={handleInputChange}
    />
  </>
)