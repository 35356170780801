import React, { useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

import { Image, ForestryLink } from '../../../../../utilities'

import * as styles from './megaMenu.module.scss'

export default ({ activeMenu, setActiveMenu, setOpen }) => {
  const { site: { siteMetadata: { mobileMenu: { megaMenus } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        mobileMenu {
          megaMenus {
            arrow
            arrowDark
            heading
            supportText
            title
            links {
              description
              icon
              image
              label
              page
            }
            icons {
              icon
              path
              text
            }
          }
        }
      }
    }
  }`)

  const handleClick = () => {
    setOpen(false)
  }

  return (
    <div className={styles.megaMenus}>
      {megaMenus.filter(menu => activeMenu === menu.title).map((menu,i) => (
        <div key={i} className={styles.megaMenu + ` ${activeMenu === menu.title ? `${styles.show}` : ""}`}>
          <div className={styles.left}>
            <h3>{menu.heading}</h3>
            <ul>
              {menu.links.map((link,i) => (
                <li key={link.page}>
                  <ForestryLink activeClassName={styles.active} className={styles.link} to={link.page} onClick={handleClick}>
                    <Image className={styles.linkIcon} src={link.icon} />
                    <p>{link.label}</p>
                  </ForestryLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  )
}