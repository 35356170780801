// extracted by mini-css-extract-plugin
export var buttons = "bG_ck";
export var left = "bG_bH";
export var media = "bG_gX";
export var right = "bG_bT";
export var section = "bG_j";
export var selected = "bG_bb";
export var signature = "bG_gW";
export var testimonial = "bG_gV";
export var text = "bG_cg";
export var textContainer = "bG_bY";
export var video = "bG_fT";