// extracted by mini-css-extract-plugin
export var arrow = "bl_5";
export var body = "bl_c";
export var circle = "bl_ff";
export var customer = "bl_fd";
export var feedback = "bl_fc";
export var feedbacks = "bl_d9";
export var footer = "bl_cl";
export var footerText = "bl_cm";
export var left = "bl_bH";
export var nameLocation = "bl_fg";
export var padding = "bl_dB";
export var right = "bl_bT";
export var section = "bl_j";
export var selected = "bl_bb";
export var selectors = "bl_d8";
export var subtitle = "bl_cC";
export var text = "bl_cg";
export var title = "bl_g";
export var wrapper = "bl_fb";