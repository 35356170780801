import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import * as styles from './topBanner.module.scss'

export default () => {
  const { site: { siteMetadata: { topBanner: { content, show} } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        topBanner {
          content
          show
        }
      }
    }
  }`)

  if (!show) {
    return null
  }

  return <div className={styles.topBanner}>
    <span className={styles.icon}>i</span>
    <ReactMarkdown className={styles.content}>{content}</ReactMarkdown>
  </div>
}