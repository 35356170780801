import React from 'react'
import * as styles from '../support.module.scss'

export default ({ search, setSearch, label }) => {

  const handleChange = e => {
    setSearch(e.target.value)
  }

  return (
    <div className={styles.search}>
      <p>{label}</p>
      <div className={styles.inputContainer}>
        <input className={styles.searchInput} value={search} onChange={handleChange}/>
      </div>
    </div>
  )
}
  