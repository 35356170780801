import React, { useContext } from 'react'
import * as styles from './button.module.scss'
import { Context } from '../../../../context'

export default({ setOpen, open, activeMenu, setActiveMenu }) => {
  const context = useContext(Context);
  return (
    <button onClick={() => {
        setOpen(!open);
        setActiveMenu('');
      }} className={styles.button + ` ${open ? `${styles.open}`: ""}` + ` ${context && context.scrolled ? `${styles.scrolled}` : ""}`}>
      <div></div>
      <div></div>
      <div></div>
      <div className={styles.x}>X</div>
    </button>
  )
}