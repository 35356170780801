import React from 'react'
import { OverlapImages, Image } from '../../../../utilities'
import * as styles from './about.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  return (
    <div className={styles.section} id={block.type}>
      {block.videoUrl &&
        <div className={styles.videoContainer}>
          <div className={styles.videoWrapper}>
            <iframe
              width="560"
              height="349"
              src={`https://www.youtube.com/embed/${block.videoUrl.split('/')[block.videoUrl.split('/').length - 1]}`}
              allowFullScreen>
            </iframe>
          </div>
        </div>}
      <OverlapImages imageOne={block.aboutTopImage} imageTwo={block.aboutBottomImage} />
      <div className={styles.topContainer}>
        <h2 className={styles.title}>{block.blockTitle}</h2>
        <ReactMarkdown className={styles.text}>{block.aboutTopBody}</ReactMarkdown>
        <div className={styles.social}>
          {block.socialLinks.map(link => (
            <a key={link.label} href={link.url} target='_blank' rel='noreferrer' className={styles.link}><Image src={link.icon} className={styles.icon}></Image></a>
          ))}
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <h2 className={styles.title}>{block.aboutBottomTitle}</h2>
        <ReactMarkdown className={styles.text}>{block.aboutBottomBody}</ReactMarkdown>
        <div className={styles.buttons}>
          {block.aboutBottomButtons.map((button, i) => (
            <SharedTextButton key={i} button={button.sharedTextButton} />
          ))}
        </div>
      </div>
    </div>
  )
}