import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import { Context } from '../../../context'
import { Image } from '../../../../utilities'
import * as styles from './supportCarousel.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import Trim from './trim'
import Fade from 'react-reveal/Fade'
import Wave from 'react-wavify'
import { markdown } from 'markdown'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const context = useContext(Context);
  const [showRight, setShowRight] = useState(true);
  const [showLeft, setShowLeft] = useState(false);
  const [faqArray, setFaqArray] = useState([]);
  const [category, setCategory] = useState(block.categories ? block.categories[0]: null);
  const ref = useRef(null);
  const subcategories = ['Billing', 'General Support', 'My Account', 'Connectivity', 'Troubleshooting']

  const categoryIconMap = {
    'Billing': '/billing-support-icon.png',
    'Home': '/residential-support-icon.png',
    'Business': '/business-support-icon.png'
  }

  const faqIconMap = {
    'Billing': '/billing-support-icon.png',
    'Residential': '/residential-support-icon.png',
    'Business': '/business-support-icon.png'
  }

  useEffect(() => {
    if (context && context.faqWithHits && context.faqWithHits.length) {
      setFaqArray(context.faqWithHits.filter(faq => faq[1].target === category || faq[1].target === 'All').filter(faq => faq[0] && faq[1]).map(faq => {
          const subcategories = faq[1].subcategory.map(x => x.subcategory)
          const icon = category !== 'All' ? categoryIconMap[category] : faqIconMap[subcategories[0]]

          return ({
            subcategory: faq[1].subcategory, 
            icon: icon, 
            answer: faq[1].answer,
            question: faq[0],
            target: faq[1].target
          })
      }).slice(0, 5))
    }
  }, [context, category])
  
  const moveRight = () => {
    if (ref.current) {
      const distance = ref.current.offsetWidth;
      ref.current.scrollLeft = ref.current.scrollLeft + distance;
      setShowLeft(true);
      setShowRight(false);
    }
  }

  const moveLeft = () => {
    if (ref.current) {
      const distance = ref.current.offsetWidth;
      ref.current.scrollLeft = ref.current.scrollLeft - distance;
      setShowLeft(false);
      setShowRight(true);
    }
  }

  const handleCategory = e => {
    setCategory(e.target.innerText);
    setShowRight(true);
    setShowLeft(false);
    ref.current.scrollLeft = 0;
  }

  return (
    <div className={styles.section} id={block.type}>
      <h2 className={styles.title}>{block.blockTitle}</h2>
      <div className={styles.contactIcons}>
        {block.contactIcons.map((icon, i) => (
          <a key={i} href={icon.path} className={styles.iconContainer}>
            <Image className={styles.contactIcon} src={icon.icon} />
            <p>{icon.text}</p>
          </a>
        ))}
      </div>
      <h4 className={styles.subtitle}>{block.supportCarouselSubtitle}</h4>
      <ReactMarkdown className={styles.body}>{block.supportCarouselBody}</ReactMarkdown>
      <div className={styles.selectors}>
        {showLeft &&
        <button className={styles.left} onClick={moveLeft}><Image src={block.arrowLeft} className={styles.arrow} /></button>}
        {showRight &&
        <button className={styles.right} onClick={moveRight}><Image src={block.arrowRight} className={styles.arrow} /></button>}
        {block.categories && block.categories.length > 1 && block.categories.map((name, i) => (
          <button key={i} onClick={handleCategory} className={styles.category + ` ${category === name ? `${styles.current}` : ""}`}>{name}</button>
        ))}
      </div>
      <div className={styles.issues} ref={ref}>
        {faqArray && faqArray.map(faq => (
          <Link key={faq.question} to={`/support?section=faq${faq.question}`} className={styles.issueContainer}>
            <Image src={faq && faq['icon']} className={styles.icon} />
            <div className={styles.issueText}>
              <p className={styles.issue}>{faq.question}</p>
              {/* <p className={styles.answer}><Trim text={faq.answer} /></p> */}
              {faq.answer && <ReactMarkdown>{faq.answer}</ReactMarkdown>}
            </div>
            <div className={styles.fade} />
          </Link>
        ))}
        <div className={styles.padding}></div>
      </div>
      <div className={styles.footer}>
        <p className={styles.footerText}>{block.supportCarouselFooterText}</p>
        <SharedTextButton button={block.sharedTextButton} />
      </div>
    </div>
  )
}