import React, { useContext, useState } from 'react'
import { Context } from '../../../context'
import * as styles from './infoButton.module.scss'
import { ForestryLink } from '../../../../utilities'
import { useStaticQuery, graphql } from 'gatsby'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

export default () => {
  const context = useContext(Context);
  const [windowOpen, setWindowOpen] = useState(false);
  const { site: { siteMetadata: { infoButton: { body, form, heading, icon, linkText, linkType, page, title, url, show, call } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        infoButton {
          body
          form
          heading
          icon
          linkType
          page
          title
          url
          show
          call
        }
      }
    }
  }`)

  const handleClick = () => {
    if (context) {
      context.setForm(form);
      context.setShowForm(true);
      setWindowOpen(false);
    }
  }

  if (!show) {
    return null;
  }

  return (

    <section className={styles.section}>
      {linkType !== 'Call' && <>
        <div className={styles.container + ` ${windowOpen ? `${styles.open}` :''}`}>
          <div className={styles.content}>
            <h4 className={styles.title}>{title}</h4>
            <h3 className={styles.heading}>{heading}</h3>
            <ReactMarkdown className={styles.body}>{body}</ReactMarkdown>
            {linkType === 'Page' ? (
              <ForestryLink to={page} className={styles.link} onClick={() => setWindowOpen(false)}>{linkText}</ForestryLink>
            ) : linkType === 'Form' ? (
              <button className={styles.link} onClick={handleClick}>{linkText}</button>
            ) : linkType === 'Url' ? (
              <a href={url} target='_blank' rel='noreferrer' onClick={() => setWindowOpen(false)}>{linkText}</a>
            ) : null}
          </div>
        </div>
        <button style={{backgroundImage: `url(${icon})`}} className={styles.button + ` ${windowOpen ? `${styles.mobileHide}` : ''}`} onClick={() => setWindowOpen(!windowOpen)}/>
        <button className={styles.close + ` ${windowOpen ? `${styles.show}` : ''}`} onClick={() => setWindowOpen(false)}>Close</button>
      </>}

      {linkType === 'Call' && <>
      <a href={`tel:${call}`} style={{backgroundImage: `url(${icon})`}} className={styles.button} />
      </>}
      
    </section>
  )

}