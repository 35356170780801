import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

import { Image } from '../../../../../utilities'

import * as styles from '../blogGrid.module.scss'

export default ({ search, setSearch }) => {

  const handleChange = e => {
    setSearch(e.target.value)
  }

  return (
    <div className={styles.search}>
      <div className={styles.inputContainer}>
        <input className={styles.searchInput} value={search} onChange={handleChange}/>
        <Image className={styles.icon} src={'/search.svg'} container />
      </div>
    </div>
  )
}
  