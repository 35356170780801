// extracted by mini-css-extract-plugin
export var bottomContainer = "F_cj";
export var buttons = "F_ck";
export var icon = "F_br";
export var link = "F_bp";
export var section = "F_j";
export var social = "F_ch";
export var text = "F_cg";
export var title = "F_g";
export var topContainer = "F_cf";
export var videoContainer = "F_cc";
export var videoWrapper = "F_cd";