import React, { useState, useRef, useEffect, useContext } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './businessInternetSpeeds.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import Wave from 'react-wavify';
import { Context } from '../../../context'
import CountUp from 'react-countup'

export default ({ block }) => {
  const [pause, setPause] = useState(true);
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);
  const [showCounters, setShowCounters] = useState(false);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 30 : 30)
  const context = useContext(Context);
  const ref = useRef(null);
  const counters = useRef(null)
  const style = typeof window !== 'undefined' ? document.documentElement.style : null;

  if (block.featureColumnsFooter && style && block.featureColumnsFooter.length > 200)
    style.setProperty('--wave-height', '105vw');
  else if (style)
    style.setProperty('--wave-height', '90vw');

  useEffect(() => {
    const changeWidth = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
        setAmplitude(window.innerWidth / 30)
      }, 1000)
    }
    changeWidth();
  }, [context])
  
  useEffect(() => {
    if (!context.prefersReducedMotion && ref.current && ref.current.getBoundingClientRect().top < (context.scroll + window.innerHeight) && ref.current.getBoundingClientRect().bottom > 0) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
    if (counters.current && counters.current.getBoundingClientRect().top < (context.scroll + window.innerHeight))
      setShowCounters(true);
  }, [context])

  return (
    <div className={styles.section} ref={ref} id={block.type}>
      <div className={styles.waves}>
        <div className={styles.waveOne}>
          <Wave fill="url(#gradient)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude,
              speed: .2,
              points: 3,
            }}
            preserveAspectRatio="xMidYMin meet"
          >
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(30)">
                <stop offset="0%"  stopColor="#1397c7" />
                <stop offset="70%" stopColor="#003D79" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.waveTwo}>
          <Wave fill="url(#gradient2)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude * .8,
              speed: .2,
              points: 6,
            }}
            preserveAspectRatio="xMidYMin meet"
          >
            <defs>
              <linearGradient id="gradient2" gradientTransform="rotate(50)">
                <stop offset="-20%"  stopColor="#1397c7" />
                <stop offset="70%" stopColor="#033c75" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.waveThree}>
          <Wave fill="url(#gradient3)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude,
              speed: .2,
              points: 7,
            }}
            preserveAspectRatio="xMidYMin meet"
          >
            <defs>
              <linearGradient id="gradient3" gradientTransform="rotate(60)">
                <stop offset="0%"  stopColor="#1397c7" />
                <stop offset="70%" stopColor="#003D79" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
      </div>
      <div className={styles.container}>
        <h2 className={styles.title}>{block.blockTitle}</h2>
        {block.businessInternetSpeedsText &&
        <p className={styles.text}>{block.businessInternetSpeedsText}</p>}
        <div className={styles.buttons}>
          {block.businessInternetSpeedsButtons && block.businessInternetSpeedsButtons.map(({sharedTextButton}, i) => (
            <SharedTextButton key={i} button={sharedTextButton} />
          ))}
        </div>
        {block.businessInternetSpeedsInformation &&
          <p className={styles.information}>{block.businessInternetSpeedsInformation}</p>}
        <div className={styles.columns}>
          {block.businessInternetSpeedsSpeeds && block.businessInternetSpeedsSpeeds.map((speed, i) => (
            <div key={i} className={styles.column} ref={counters}>
              {showCounters &&
              <div className={styles.download}>
                <CountUp decimals={0} useEasing={false} duration={2} end={speed.downloadSpeed} />
                <Image src={block.arrowDown} className={styles.down} />
              </div>}
              {showCounters &&
              <div className={styles.units}>Mbps</div>}
              {showCounters &&
              <div className={styles.upload}>
                <CountUp decimals={0} useEasing={false} duration={2} end={speed.uploadSpeed} />
                <Image src={block.arrowUp} className={styles.up} />
              </div>}
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <p>{block.businessInternetSpeedsFooterMessage}</p>
          <SharedTextButton button={block.sharedTextButton} />
        </div>
      </div>
    </div>
  )
}