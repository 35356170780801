import React, { useContext } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './commandiq.module.scss'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  console.log(block)
  return (
    <div className={styles.section + ` ${block.LoR ? `${styles.grey}` : `${styles.grey}`}`} id={block.type}>
      <div className={styles.left + ` ${block.LoR ? `${styles.reverse}` : ""}`}>
        <h1 className={styles.heading}>{block.heading}</h1>
        <div className={styles.textSection}>{block.text && <ReactMarkdown>{block.text}</ReactMarkdown>}</div>
        {block.list_of_icons && block.list_of_icons.map((icon, i) =>
          (<div key={i} className={styles.iconGroup}>
            <Image src={icon.icon} className={styles.icon}  />
              <div className={styles.iconText}>
              <h3>{icon.icon_heading}</h3>
              {icon.icon_body_text && <ReactMarkdown>{icon.icon_body_text}</ReactMarkdown>}
              </div>
            </div>)
        )}
      </div>
      <div className={styles.right}>
        <Image src={block.main_image} className={styles.image}  />
        {block.caption && <ReactMarkdown>{block.caption}</ReactMarkdown>}
        <div className={styles.appStores}>
          {block.appStoreIcons?.map((icon, i) => (
            <a key={i} href={icon.url} target="_blank" rel="noreferrer">
              <Image src={icon.icon} className={styles.storeIcon} />
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}