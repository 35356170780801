// extracted by mini-css-extract-plugin
export var container = "X_b";
export var content = "X_b2";
export var icon = "X_br";
export var iconContainer = "X_b0";
export var icons = "X_bs";
export var image = "X_k";
export var label = "X_cF";
export var section = "X_j";
export var text = "X_cg";
export var title = "X_g";