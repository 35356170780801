import React, { useContext, useEffect } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Context } from '../../../context'
import { Image } from '../../../../utilities'

import * as styles from './logo.module.scss'

export default ({ activeMenu }) => {
  const context = useContext(Context);
  const { site: { siteMetadata: { header: { mainLogo } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        header {
          mainLogo
        }
      }
    }
  }`)

  // useEffect(() => {
  //   if (context.scroll > 15) {
  //     setScrolled(true);
  //   } else setScrolled(false);
    
  // }, [context && context.scroll])

  return (
    <div className={styles.logo + ` ${context && context.scrolled && !activeMenu ? `${styles.scrolled}` : ""}`}>
      <Link className={styles.link} to='/'>
        <Image className={styles.image} src={mainLogo} container />
      </Link>
    </div>
  )
}