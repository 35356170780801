// extracted by mini-css-extract-plugin
export var bottom = "bB_s";
export var contact = "bB_f2";
export var faqCat = "bB_f9";
export var faqCatDescription = "bB_gd";
export var faqCatTitle = "bB_gc";
export var faqCategories = "bB_f8";
export var faqIcon = "bB_gb";
export var footerText = "bB_cm";
export var icon = "bB_br";
export var iconContainer = "bB_b0";
export var iconText = "bB_f4";
export var immediate = "bB_f3";
export var inputContainer = "bB_cs";
export var linksSubtitle = "bB_f6";
export var linksTitle = "bB_f5";
export var search = "bB_cr";
export var searchInput = "bB_ct";
export var section = "bB_j";
export var selected = "bB_bb";
export var supportButton = "bB_gf";
export var supportLinks = "bB_f7";
export var top = "bB_l";
export var wave = "bB_f1";