import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { MyBtc } from '../'
import { Image, ForestryLink } from '../../../../../utilities'

import * as styles from './icons.module.scss'

export default ({ activeMenu, setActiveMenu }) => {
  const { site: { siteMetadata: { mobileMenu: { mainIcons } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        mobileMenu {
          mainIcons {
            icon
            linkText
            linkPath
          }
        }
      }
    }
  }`)

  const handleClick = () => {
    setActiveMenu(
      activeMenu === 'My BTC' ? undefined : 'My BTC'
    )
  }

  return (
    <div className={styles.icons}>
      {mainIcons.map(icon => (
        <div key={icon.linkText}>
        {icon.linkText === 'My BTC' ? (
          <button className={styles.link} onClick={handleClick}>
            <Image
              className={styles.icon + ` ${icon.icon === '/business-phone.svg' ? `${styles.bigger}` : ''}`}
              src={icon.icon} container />
            {icon.linkText}
          </button>
        ) : 
          <a className={styles.link} href={icon.linkPath} target='_blank' rel='noreferrer'>
            <Image
              className={styles.icon + ` ${icon.icon === '/business-phone.svg' ? `${styles.bigger}` : ''}`}
              src={icon.icon} container />
            {icon.linkText}
          </a>}
      </div>
      ))}
      {activeMenu === 'My BTC' &&
        <MyBtc activeMenu={activeMenu} setActiveMenu={setActiveMenu} />}
    </div>
  )
}