import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'

import { ForestryLink } from '../../../../utilities'

import * as styles from './copyright.module.scss'

export default ({ }) => {
  
  const { site: { siteMetadata: { footer: { copyright, termsConditions, termsConditionsLink, privacy_policy, privacy_policy_link} } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        footer {
          copyright
          termsConditions
          termsConditionsLink
          privacy_policy
          privacy_policy_link
        }
      }
    }
  }`)

  return (
    <div className={styles.container}>
      <p className={styles.text}>© {moment().year()} {copyright}
        <span>
          <ForestryLink className={styles.link} to={termsConditionsLink}>
            {termsConditions}
          </ForestryLink>
        </span>
        <span>
          <a key='/customer-privacy' className={styles.link} href='/customer-privacy'>
            {privacy_policy}
          </a>
        </span>
      </p>
      <a href='http://station8branding.com' target='_blank' rel='noreferrer' className={styles.station8}>Web design by Station8</a>
    </div>
  )
}
