// extracted by mini-css-extract-plugin
export var buttons = "bf_ck";
export var container = "bf_b";
export var content = "bf_b2";
export var icon = "bf_br";
export var iconContainer = "bf_b0";
export var image = "bf_k";
export var section = "bf_j";
export var selected = "bf_bb";
export var tab = "bf_dD";
export var tabText = "bf_dC";