import { functions, isEqual, omit } from 'lodash'
import React, { useState, useEffect, useRef } from 'react'

const Map = ({ options, className, onMountProps }) => {
  const ref = useRef()
  const [map, setMap] = useState()

  const urls = [
    'https://www.google.com/maps/d/u/1/kml?mid=1P5kNRK_ucWZzK1ay8QVs3QNWhJV-sy_z',
  ]

  const addKml = (map) => {
    if (typeof window !== 'undefined') {
      urls.forEach((url, i) => {
        const kmlLayer = new window.google.maps.KmlLayer({
          url: url,
          suppressInfoWindows: false,
          preserveViewport: true,
          map: map,
          zIndex: 2,
        });
      })
    }
  }

  useEffect(() => {
    if (window.google) {
      const map = new window.google.maps.Map(ref.current, { ...options })
      setMap(map)
    }
  }, [options, onMountProps])

  useEffect(() => {
    if (map) addKml(map)
  }, [map])

  return (
    <div
      {...{ ref, className }}
    />
  )
}
const shouldNotUpdate = (props, nextProps) => {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)]
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs))
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every(fn => props[fn].toString() === nextProps[fn].toString())
  return noPropChange && noFuncChange
}

export default React.memo(Map, shouldNotUpdate)