import React, { useState, useCallback, useEffect } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './featureTabs.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

export default ({ block }) => {
  const [selected, setSelected] = useState(0);
  const style = typeof window !== 'undefined' ? document.documentElement.style : null;
  const ref = useCallback(el => {
    if (el && +el.id === selected) {
      console.log(el.offsetHeight)
      style.setProperty('--container-height', `${el.offsetHeight}px`);
    }
  }, [selected]);

  return (
    <div className={styles.section} id={block.type}>
      <div className={styles.container}>
        <Image src={block.featureTabsImage} className={styles.image} />
        <div className={styles.content}>
          <div className={styles.iconContainer}>
            {block.featureTabsTabs && block.featureTabsTabs.length ? block.featureTabsTabs.map((tab, i) => (
              <button
                onClick={() => setSelected(i)}
                onMouseOver={() => setSelected(i)}
                key={tab.icon}
                className={selected === i ? ` ${styles.selected}` : ""}>
                <Image src={tab.icon} className={styles.icon} />
              </button>
            )) : null}
          </div>
          <div className={styles.tabText}>
            {block.featureTabsTabs && block.featureTabsTabs.length ? block.featureTabsTabs.map((tab, i) => (
              <div id={i} key={i} className={styles.tab + ` ${selected === i ? `${styles.selected}` : ""}`} ref={ref}>
                <h2>{tab.title}</h2>
                <ReactMarkdown>{tab.text}</ReactMarkdown>
              </div>
            )) : null}
          </div>
          <div className={styles.buttons}>
            {block.featureTabsButtons && block.featureTabsButtons.length ? block.featureTabsButtons.map(({sharedTextButton}, i) => (
              <SharedTextButton key={i} button={sharedTextButton} />
            )) : null}
          </div>
        </div>
      </div>
    </div>
  )
}