// extracted by mini-css-extract-plugin
export var appStores = "T_c1";
export var grey = "T_c2";
export var icon = "T_br";
export var iconGroup = "T_c0";
export var image = "T_k";
export var left = "T_bH";
export var reverse = "T_cZ";
export var right = "T_bT";
export var section = "T_j";
export var textSection = "T_cY";