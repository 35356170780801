// extracted by mini-css-extract-plugin
export var bottomWave = "bs_cX";
export var button = "bs_bL";
export var buttonContainer = "bs_cH";
export var buttonFooter = "bs_fp";
export var container = "bs_b";
export var middleWave = "bs_cW";
export var section = "bs_j";
export var subtitle = "bs_cC";
export var textContainer = "bs_bY";
export var title = "bs_g";