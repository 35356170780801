import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../context'
import Map from './googleMap'
import * as styles from './coverageMap.module.scss'
import { ForestryLink } from '../../../../utilities';


export default ({ block }) => {
  const context = useContext(Context)
  const [zip, setZip] = useState(context ? context.zip : '');
  const [coordinates, setCoordinates] = useState({ lat: 35.954702, lng: -95.890359 });
  const [status, setStatus] = useState([]);
  const [zoom, setZoom] = useState(7);

  const urls = [
    'https://www.google.com/maps/d/u/1/kml?mid=1P5kNRK_ucWZzK1ay8QVs3QNWhJV-sy_z',
    'https://www.google.com/maps/d/u/1/kml?mid=1-qtzeDQKYzv3K5ayUoHbXaRsDccQE0sx',
    'https://www.google.com/maps/d/u/1/kml?mid=1gPhd74L3C72CtzQHFVNsoF5qoGSk2Dxx',
    'https://www.google.com/maps/d/u/2/kml?mid=1YXDF0EypoPm8CsgB1cRzPWszhWvW7jH0'
  ]

  const categories = [
    'Business','Coming Soon 2021','Residential'
  ]

  const addKml = (map, props) => {
    let array = [];
    if (typeof window !== 'undefined') {
      props.urls.forEach((url, i) => {
        const kmlLayer = new window.google.maps.KmlLayer({
          url: url,
          suppressInfoWindows: false,
          preserveViewport: true,
          map: map,
          zIndex: 2
        });
        kmlLayer.addListener('defaultviewport_changed', () => {
          if (coordinates)
            if (kmlLayer.getDefaultViewport().contains(coordinates))
              array.push(categories[i])
        })
      })
      google.maps.event.addListener(map, 'tilesloaded', function() {
        if (zip)
          setStatus(array);
      })
    }
  }

  const mapProps = {
    options: {
      center: coordinates,
      zoom: zoom,
    },
    onMount: addKml, 
    onMountProps: {urls, coordinates}
  }

  const handleChange = e => {
    if (Number.isInteger(+e.target.value))
      setZip(e.target.value);
  }

  useEffect(() => {
    if (zip && zip.length === 5 && window.google) {
      window.fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${process.env.GATSBY_GOOGLE_MAPS_API}`)
      .then(response => response.json()
        .then(res => {
          if (res) {
            console.log(res.results[0])
            const latLong = res.results[0].geometry.location;
            setCoordinates({lat: parseFloat(latLong.lat), lng: parseFloat(latLong.lng)});
            setZoom(12);
          }
        })
      )
      .catch(err => console.log(err));
    }
  }, [zip])

  return (
    <section className={styles.section}>
      <div className={styles.search}>
        <h2 className={styles.title}>{block.blockTitle}</h2>
        <input className={styles.input} placeholder='Enter zip' value={zip} onChange={handleChange} maxLength={5}/>
        <p className={styles.offered}>Services offered in your area:</p>
        <div className={styles.categories}>
          {block.service_links?.length > 0 && block.service_links.filter(e => (status.includes(e.label))).map((category, i) => (
            <ForestryLink to={category.page} key={i}>
              <p>{category.label}</p>
            </ForestryLink>
          ))}
        </div>
        <p className={styles.footerText}>{block.coverageFooterText}</p>
      </div>
      <div className={styles.mapContainer}>
       <Map {...mapProps} className={styles.map} coordinates={coordinates} />
      </div>
    </section>
  )
}