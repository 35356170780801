import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactPlayer from "react-player"

import * as styles from "./videos.module.scss"

const Videos = ({ block: { label, videos }}) => {
  const {
    allVideosJson: { nodes },
  } = useStaticQuery(graphql`
    {
      allVideosJson {
        nodes {
          video
          title
          question
        }
      }
    }
  `)

  return (
    <section className={styles.section + ` ${styles.active}`}>
      <h2 className={styles.heading}>{label}</h2>
      <div className={styles.videos}>
        {videos && videos.length
          ? videos.map((v, i) => {

              return (
                <div className={styles.nodeContainer} key={i}>
                  <div className={styles.video}>
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      url={nodes.find(x => x.title === v.video)?.video}
                      light
                      controls
                    />
                  </div>
                  <p className={styles.label}>{v.video}</p>
                </div>
              )
            })
          : null}
      </div>
    </section>
  )
}

export default Videos
