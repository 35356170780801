import React, { useEffect } from 'react'
import { About, BandwidthEstimator, Banner, BlogGrid, BusinessCoverage, BusinessInternetSpeeds, Careers, CloudCommunications, CloudPlans, Commandiq, Contact, CoverageMap, Details, Devices, FeatureColumns, FeatureTabs, Feedback, FullPageText, Hero, HeroWithLogo, HubspotForm, Legal, PrivacyPolicy, Promotions, ResidentialInternet, ResidentialCoverage, ResidentialVoice, ScholarshipBanner, Solutions, Speeds, Support, SupportVideos, SupportCarousel, Team, TechProtect, TextAndImage, Testimonials, Timeline, Videos, WhyFiber } from './blocks'
import heroWithLogo from './blocks/heroWithLogo/heroWithLogo'

import * as styles from './main.module.scss'
import PageForm from './blocks/pageForm/pageForm'

export default ({ blocks, children }) => {

  return (
    <main className={styles.main} id='main'>
      {blocks && blocks.length ? (
        <div className={styles.blocks}>
          {blocks && blocks.map((block, i) => {
            return block.type === 'main-promotions' ? (
              <Promotions key={i} block={block} />
            ) : block.type === 'main-about' ? (
              <About key={i} block={block} />
            ) : block.type === 'main-bandwidth-estimator' ? (
              <BandwidthEstimator key={i} block={block} />
            ) : block.type === 'main-banner' ? (
              <Banner key={i} block={block} />
            ) : block.type === 'main-blog' ? (
              <BlogGrid key={i} block={block} />
            ) : block.type === 'main-business-map' ? (
              <BusinessCoverage key={i} block={block} />
            ) : block.type === 'main-business-internet-speeds' ? (
              <BusinessInternetSpeeds key={i} block={block} />
            ) : block.type === 'main-careers' ? (
              <Careers key={i} block={block} />
            ) : block.type === 'main-cloud-communications' ? (
              <CloudCommunications key={i} block={block} />
            ) : block.type === 'main-cloud-plans' ? (
              <CloudPlans key={i} block={block} />
            ) : block.type === 'main-commandiq' ? (
              <Commandiq key={i} block={block} />
            ) : block.type === 'main-contact' ? (
              <Contact key={i} block={block} />
            ) : block.type === 'main-coverage' ? (
              <CoverageMap key={i} block={block} />
            ) : block.type === 'main-details' ? (
              <Details key={i} block={block} />
            ) : block.type === 'main-devices' ? (
              <Devices key={i} block={block} />
            ) : block.type === 'main-feature-columns' ? (
              <FeatureColumns key={i} block={block} />
            ) : block.type === 'main-feature-tabs' ? (
              <FeatureTabs key={i} block={block} />
            ) : block.type === 'main-feedback' ? (
              <Feedback key={i} block={block} />
            ) : block.type === 'main-full-page-text' ? (
              <FullPageText key={i} block={block} />
            ) : block.type === 'main-hero' ? (
              <Hero key={i} block={block} />
            ) : block.type === 'main-hero-with-logo' ? (
              <HeroWithLogo key={i} block={block} />
            ) : block.type === 'main-hubspot-form' ? (
              <HubspotForm key={i} block={block} />
            ) : block.type === 'main-support-how-to-videos' ? (
              <Videos key={i} block={block} />
            ) : block.type === 'main-support-legal' ? (
              <Legal key={i} block={block} />
            ) : block.type === 'main-support-privacy-policy' ? (
              <PrivacyPolicy key={i} block={block} />
            ) : block.type === 'main-residential-internet' ? (
              <ResidentialInternet key={i} block={block} />
            ) : block.type === 'main-residential-map' ? (
              <ResidentialCoverage key={i} block={block} />
            ) : block.type === 'main-residential-voice' ? (
              <ResidentialVoice key={i} block={block} />
            ) : block.type === 'main-solutions' ? (
              <Solutions key={i} block={block} />
            ) : block.type === 'main-scholarship-banner' ? (
              <ScholarshipBanner key={i} block={block} />
            ) : block.type === 'main-speeds' ? (
              <Speeds key={i} block={block} />
            ) : block.type === 'main-support' ? (
              <Support key={i} block={block} />
            ) : block.type === 'main-support-videos' ? (
              <SupportVideos key={i} block={block} />
            ) : block.type === 'main-support-carousel' ? (
              <SupportCarousel key={i} block={block} />
            ) : block.type === 'main-team' ? (
              <Team key={i} block={block} />
            ) : block.type === 'main-tech-protect' ? (
              <TechProtect key={i} block={block} />
            ) : block.type === 'main-testimonials' ? (
              <Testimonials key={i} block={block} />
            ) : block.type === 'main-text-and-image' ? (
              <TextAndImage key={i} block={block} />
            ) : block.type === 'main-timeline' ? (
              <Timeline key={i} block={block} />
            ) : block.type === 'main-why-fiber' ? (
              <WhyFiber key={i} block={block} />
            ) : block.type === 'main-page-form' ? (
              <PageForm key={i} block={block} />
            ) : <p key={i} className={styles.title}>{block.type} does not exist</p>
          })}
        </div>
      ) : null}
      {children}
    </main>
  )
}