import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Context } from '../../../context'
import { Image, ForestryLink } from '../../../../utilities'

import * as styles from './icons.module.scss'

export default ({ activeMenu, setActiveMenu }) => {
  const context = useContext(Context);
  const { site: { siteMetadata: { header: { mainIcons } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        header {
          mainIcons {
            icon
            linkText
            linkPath
          }
        }
      }
    }
  }`)

  return (
    <div className={styles.icons + ` ${context && context.scrolled && !activeMenu ? `${styles.scrolled}` : ""}`}>
      {mainIcons.map((icon, i) => {
        if (icon.linkText === 'My BTC') return (
          <button key={i} className={styles.link + ' ' + styles.noMobile} onClick={() => setActiveMenu('My BTC')}>
            <Image
              className={styles.icon + ` ${icon.icon === '/business-phone.svg' ? `${styles.bigger}` : ''}`}
              src={icon.icon} container />
            {icon.linkText}
          </button>)
        else return (
          <a key={i} className={styles.link} href={icon.linkPath} target='_blank' rel='noreferrer'>
            <Image
              className={styles.icon + ` ${icon.icon === '/business-phone.svg' ? `${styles.bigger}` : ''}`}
              src={icon.icon} container />
            {icon.linkText}
          </a>
        )
    })}
    </div>
  )
}