import React from 'react'

export default ({ styles, getStyle, field, formValues, handleInputChange }) => (
  <>
    <p className={styles.textLabel}>{field.label}</p>
    <div className={styles.slideContainer}>
      <div className={styles.colorContainer}>
        <div className={styles.color + ` ${getStyle(field.label)}`}></div>
      </div>
      <input 
        className={styles.slider}
        name={field.label} 
        value={formValues[field.label]}
        type='range'
        min={field.min} 
        max={field.max} 
        step={field.step} 
        onChange={handleInputChange}
      />
    </div>
    <p className={styles.sliderValue}>{formValues[field.label]}</p>
  </>
)