import React, { useState, useContext, useMemo } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import * as styles from './blogGrid.module.scss'
import { Image, ForestryLink, FormatDate } from '../../../../utilities'
import { Context } from '../../../context'
import ReactMarkdown from 'react-markdown'
import Search from './blogSearch/blogSearch'
import SearchResult from './blogSearch/searchResult'
import removeMarkdown from 'markdown-to-text'

export default ({ block }) => {
  const [current, setCurrent] = useState('');
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [results, setResults] =useState([]);
  const context = useContext(Context);
  const [selectOpen, setSelectOpen] = useState(false);
  const { allFile: { nodes } } = useStaticQuery(graphql`
  {
    allFile(
      filter: {relativeDirectory: {in: ["blog-articles"]}, childrenBlogArticlesJson: {elemMatch: {title: {ne: "Test file DO NOT DELETE (not shown on site)"}}}}
    ) {
      nodes {
        id
        relativeDirectory
        childBlogArticlesJson {
          title
          date
          heroImage
          category
          tags
          slug
          body
        }
        relativePath
      }
    }
  }`)
  const featured = block.blogFeaturedArticle.split("/").slice(-2).join("/");

  const sorted = useMemo(() => nodes.sort((a,b) =>
    new Date(a?.childBlogArticlesJson?.date) > new Date(b?.childBlogArticlesJson?.date) ? -1 : 1), [nodes])

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.categories}>
            {block.categories.map(category => (
              <button key={category} onClick={() => setCurrent(category)} className={styles.category + ` ${current === category ? `${styles.active}` : ""}`}>{category}</button>
            ))}
          </div> 
          <Search 
            search={search} 
            setSearch={setSearch}
            setCount={setCount}
            setResults={setResults}
          />
        </div>
        <SearchResult
          search={search}
          results={results}
          count={count}
          setResults={setResults}
          setSearch={setSearch}
        />
        {sorted.filter(({relativePath, childBlogArticlesJson: {category}}, i) => 
          relativePath === featured && (!current || category === current))
          .map(({childBlogArticlesJson: article}, i) => (
          <Link to={`/blog/${article.slug}`} key={i} className={styles.featured}>
            <div className={styles.featuredContainer}>
              <div className={styles.hero}>
                <Image src={article.heroImage} className={styles.image} container/>
              </div>
              <p className={styles.date}>Posted <span><FormatDate date={article.date} /></span></p>
              <h2 className={styles.title}>
                {article.title.length > 148 ? removeMarkdown(article.title).slice(0,148) + '...' : article.title}
              </h2>
              <ReactMarkdown className={styles.body}>
                {article.body.length > 300 ? removeMarkdown(article.body).slice(0,300) + '...' : article.body}
              </ReactMarkdown>
            </div>
          </Link> 
        ))}
        <div className={styles.articlesContainer}>
          {sorted.filter(({relativePath, childBlogArticlesJson: {category}}, i) => 
            relativePath !== featured && (!current || category === current))
            .map(({childBlogArticlesJson: article}, i) => (
            <Link to={`/blog/${article.slug}`} key={i} className={styles.article}>
              <div className={styles.articleContainer}>
                <div className={styles.hero}>
                  <Image src={article.heroImage} className={styles.image} container/>
                </div>
                <p className={styles.date}>Posted <span><FormatDate date={article.date} /></span></p>
                <h2 className={styles.title}>
                  {article.title.length > 50 ? removeMarkdown(article.title).slice(0,50) + '...' : article.title}
                </h2>
                <ReactMarkdown className={styles.body}>
                  {article.body.length > 100 ? removeMarkdown(article.body).slice(0,100) + '...' : article.body}
                </ReactMarkdown>
              </div>
            </Link>  
          ))}
        </div>
      </div>
    </div>
  )
}