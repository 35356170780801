import React from 'react'
import { Image } from './'
import * as styles from './overlapImages.module.scss'
import Wave from 'react-wavify';

export default ({ imageOne, imageTwo }) => {

  return (
    <div className={styles.section}>
      <div className={styles.top}>
        <Image className={styles.topImage} src={imageOne} container />
      </div>
      <div className={styles.mobileWave}>
        <Wave fill="#003D79"
            paused={true}
            options={{
              height: 100,
              amplitude: 60,
              speed: .35,
              points: 2
            }}
        />
      </div>
      <div className={styles.waveTwo}>
        <Image className={styles.waveTwoImage} src={imageOne} container/>
      </div>
      <div className={styles.waveOne}>
        <Image className={styles.waveOneImage} src={imageTwo} container/>
      </div>
      <div className={styles.bottom}>
        <Image className={styles.bottomImage} src={imageTwo} container />
      </div>
      <div className={styles.bottomCover}>
        <Image className={styles.bottomImage} src={imageTwo} container />
      </div>
    </div>
  )
}