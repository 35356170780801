// extracted by mini-css-extract-plugin
export var container = "bz_b";
export var feature = "bz_fZ";
export var featureText = "bz_f0";
export var features = "bz_fY";
export var footer = "bz_cl";
export var footerText = "bz_cm";
export var icon = "bz_br";
export var section = "bz_j";
export var text = "bz_cg";
export var title = "bz_g";
export var waveOne = "bz_q";
export var waveThree = "bz_c4";
export var waveTwo = "bz_n";
export var waves = "bz_c3";