import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../context'
import Map from './googleMap'
import * as styles from './residentialCoverage.module.scss'


export default ({ block }) => {
  const context = useContext(Context)
  const [zip, setZip] = useState(context ? context.zip : '');
  const [coordinates, setCoordinates] = useState({ lat: 35.954702, lng: -95.890359 });
  const [zoom, setZoom] = useState(9);

  const zipCodes = block.zipCodes

  const mapProps = {
    options: {
      center: coordinates,
      zoom: zoom,
    },
    onMountProps: { coordinates }
  }

  const handleChange = e => {
    if (Number.isInteger(+e.target.value))
      setZip(e.target.value);
  }

  useEffect(() => {
    if (zip && zip.length === 5 && window.google) {
      window.fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${process.env.GATSBY_GOOGLE_MAPS_API}`)
      .then(response => response.json()
        .then(res => {
          if (res && res.results[0]?.geometry?.location) {
            const latLong = res.results[0].geometry.location;
            setCoordinates({lat: parseFloat(latLong?.lat), lng: parseFloat(latLong?.lng)});
            setZoom(12);
          }
        })
      )
      .catch(err => console.log(err));
    }
  }, [zip])

  const filteredZip = zipCodes.find(x => x.zip === zip)
  
  return (
    <section className={styles.section}>
      <div className={styles.search}>
        <h2 className={styles.title}>{block.blockTitle}</h2>
        <input className={styles.input} placeholder='Enter zip' value={zip} onChange={handleChange} maxLength={5}/>
        {filteredZip ? (
          <div className={styles.info}>
            {filteredZip.text && <p>{filteredZip.text}</p>}
            {filteredZip.callToActions.map((cta, i) => (
              <div className={styles.cta} key={i}>
                {cta.linkType === "url" &&
                <a href={cta.url} target="_blank" rel="noreferrer" key={i}>
                  {cta.text}
                </a>}
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.info}>
            {block.otherZips?.text && zip?.length === 5 && <p>{block.otherZips.text}</p>}
          </div>
        )}
        <p className={styles.footerText}>{block.coverageFooterText}</p>
      </div>
      <div className={styles.mapContainer}>
        <Map {...mapProps} className={styles.map} coordinates={coordinates} />
      </div>
    </section>
  )
}