import React, { useState, useContext, useRef, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Image, ForestryLink } from '../../../../utilities'

import * as styles from './megaMenus.module.scss'
import { Context } from '../../../context'

const MegaMenu = ({ menu, activeMenu,}) => {
  const [selected, setSelected] = useState<number | null>(null)

  const url = typeof window !== 'undefined' ? window.location.href
    .replace(window.location.origin, '') : ''

  const parseLink = link => {
    return link && link
      .replace('.forestry/content/pages', '')
      .replace('.json', '')
      .replace('home', '')
      .split('#')[0]
  }

  const links = menu.links.map(x => x.page)

  useEffect(() => {
    if (activeMenu === menu.title) {
      var matched = links.findIndex(x => parseLink(x) === url)
    
      if (matched >= 0) {
        setSelected(matched)
      } else {
        setSelected(0)
      }
    } else {
      setSelected(null)
    }
    
  }, [activeMenu])

  const isActiveLink = (link, i) => selected === i || parseLink(link.page) === url

  const isActiveContent = (i) => selected === i

  return (
    <div className={styles.megaMenu + ` ${activeMenu === menu.title ? `${styles.show}` : ""}`}>
      <div className={styles.left}>
        <h3>{menu.heading}</h3>
        <ul>
          {menu.links.map((link, i) => (
            <li key={i} onMouseOver={() => setSelected(i)} className={isActiveLink(link, i) ? `${styles.selected}` : ""}>
              <ForestryLink className={styles.link} to={link.page}>
                <Image className={styles.linkIcon} src={link.icon} />
                <p>{link.label}</p>
              </ForestryLink>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.right}>
        {menu.links.map((link, i) => (
          <div key={i} className={styles.rightContainer + ` ${isActiveContent(i) ? `${styles.show}`: ''}`}>
            <Image className={styles.linkImage} src={link.image} container />
            <div className={styles.overlay}></div>
            <div className={styles.textContainer}>
              <p>{link.description}</p>
              <div className={styles.supportIconContainer}>
                <p className={styles.support}>{menu.supportText}</p>
                {menu.icons.map((icon, i) => (
                  <a key={i} href={icon.path} className={styles.iconContainer}>
                    <Image className={styles.contactIcon} src={icon.icon} />
                    <p>{icon.text}</p>
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ({ activeMenu, setActiveMenu }) => {
  const ref = useRef(null);
  const context = useContext(Context);

  const { site: { siteMetadata: { header: { megaMenus } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        header {
          megaMenus {
            arrow
            arrowDark
            heading
            supportText
            title
            links {
              description
              icon
              image
              label
              page
            }
            icons {
              icon
              path
              text
            }
          }
        }
      }
    }
  }`)

  useEffect(() => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target) && activeMenu && !['My BTC','Blog'].includes(activeMenu) && window.innerWidth > 480) {
        setActiveMenu(undefined);
      }
    }

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [activeMenu]);

  const handleLeave = () => {
    setActiveMenu(undefined);
  }

  return (
    <div className={styles.megaMenus} ref={ref}>
      {megaMenus.map((menu, i) => (
        <div key={i} onMouseDown={handleLeave} className={styles.megaLink + ` ${activeMenu === 'Residential' ? `${styles.second}` : ""}` + ` ${activeMenu === menu.title ? `${styles.show}` : ""}`}>
          <div className={styles.linkContainer}>
            <div key={menu.title} className={styles.link}>{menu.title}
              <span className={styles.arrowDark}>
                <Image className={styles.icon} src={menu.arrowDark} container />
              </span>
            </div>
          </div>
          <div className={styles.shadow + ` ${activeMenu === 'Residential' ? `${styles.widerShadow}` : ""}`}></div>
        </div>
      ))}
      {megaMenus.map((menu, i) => (
        <MegaMenu key={i} {...{ menu, activeMenu }} />
      ))}
      <div className={styles.shadowFirst + ` ${activeMenu === 'Business' ? `${styles.show}` : ""}`}></div>
      <div className={styles.shadowSecond + ` ${activeMenu === 'Residential' ? `${styles.show}` : ""}`}></div>
    </div>
  )
}