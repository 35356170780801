import React, { useState, useEffect, useRef } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './timeline.module.scss'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const [current, setCurrent] = useState(0);
  const [animate, setAnimate] = useState(false);
  // const [nextYear, setNextYear] = useState("");
  const ref = useRef(null);
  const options = block.timelineEvents;
  const length = options.length;

  const handleRight = () => {
    setAnimate(true);
    if (ref.current) {
      let container = ref.current;
      setTimeout(() => {
        container.style.scrollBehavior = 'auto';
        container.scrollLeft = 0;
        container.style.scrollBehavior = 'smooth';
        container.scrollLeft = container.scrollLeft + container.offsetWidth;
        setCurrent(current === options.length - 1 ? 0 : current + 1);
      }, 300)
      setTimeout(() => {
        setAnimate(false);
      }, 800);
    }
  }

  const handleLeft = () => {
    setAnimate(true);
    if (ref.current) {
      let container = ref.current;
      setTimeout(() => {
          container.style.scrollBehavior = 'auto';
          container.scrollLeft = container.offsetWidth;
          container.style.scrollBehavior = 'smooth';
          container.scrollLeft = 0;
          setCurrent(current === 0 ? options.length - 1 : (current - 1));
      }, 300)
      setTimeout(() => {
        setAnimate(false);
      }, 800);
    }
  }

  // useEffect(() => {
  //   if (current === options.length - 1)
  //     setNextYear(options[0].year);
  //   else setNextYear(options[current + 1].year)
  // }, [current])

  return (
    <div className={styles.section} id={block.type}>
      <h2 className={styles.title}>{moment().year() - 1914} {block.blockTitle}</h2>
      <div className={styles.content + ` ${animate ? `${styles.shrink}` : ""}`}>
        <div className={styles.topCircle}></div>
        <div className={styles.year}>
          {options[current].year}
        </div>
        <ReactMarkdown className={styles.description}>
          {options[current].description}
        </ReactMarkdown>
        <div className={styles.bottomCircle}></div>
      </div>
      <button onClick={handleRight} className={styles.right}>
        <Image src={block.arrowRight} className={styles.icon} />
      </button>
      <button onClick={handleLeft} className={styles.left}>
        <Image src={block.arrowLeft} className={styles.icon} />
      </button>
      <Image src={options[current].image} className={styles.image + ` ${animate ? `${styles.hide}` : ""}`} container/>
      <div className={styles.nextYear + ` ${animate ? `${styles.hide}` : ""}`}>
        {options[current].year}
      </div>
      <div className={styles.waveContainer} ref={ref}>
        <div className={styles.imageContainer}>
          <Image src={block.waveImage} className={styles.wave} container/>
        </div>
        <div className={styles.imageContainer}>
          <Image src={block.waveImage} className={styles.wave} container/>
        </div>
      </div>
    </div>
  )
}