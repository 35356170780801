import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { Trim, Image } from '../../../../../utilities'
import { Context } from '../../../../context'
import * as styles from './searchResult.module.scss'

export default ({ search, setSearch, results, setResults, count }) => {
  const context = useContext(Context);

  // const parseQ = title => {
  //   return title.split('<em>').join('').split('</em>').join('');
  // }

  const parsePath = result => {
    const path = result.relativePath;

    if (path)
      return `/blog/${result.slug}`
  }

  const handleClose = () => {
    setSearch('');
    setResults([]);
  }

  const handleClick = () => {
    context.setSearch('');
    context.setResults([]);
  }

  return (
    <section className={styles.section + ` ${results.length && search ? `${styles.show}` : ""}`}>
      <div className={styles.logo}>
        <Image src={'/algolia-blue-mark.png'} className={styles.logoIcon} />
      </div>
      <button className={styles.close} onClick={handleClose}>X</button>
      <h2 className={styles.heading}>found {count} results for '{search}'</h2>
      <div className={styles.container}>
        {results.map((result,i) => (
          <div key={i} className={styles.linkContainer}>
            <Link to={parsePath(result)} onClick={handleClick} className={styles.title} 
              >
              <Trim text={result.title['value']} />
            </Link>
            <Link to={parsePath(result)} onClick={handleClick} className={styles.value} 
              >
                <Trim text={result.text['value']} />
            </Link>
          </div>
        ))}
      </div>
    </section>
  )
}