import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import slugify from 'slugify'

type props = {
  children?: React.ReactNode | [React.ReactNode],
  className?: string,
  activeClassName?: string,
  to: string,
  onClick?: any,
  partiallyActive?: boolean
}

export default ({ children, className, activeClassName, to, onClick, partiallyActive = false }: props) => {
  const { allFile: { nodes } } = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {in: ["pages"]}}) {
        nodes {
          relativePath
          childPagesJson {
            slug
            title
          }
        }
      }
    }
  `)

  const [page, setPage] = useState(undefined)

  useEffect(() => {
    if (nodes.length && to) {
      setPage(nodes.find(page => page.childPagesJson.slug === to))
    }
  }, [nodes])

  return page && page.childPagesJson ? (
    <Link partiallyActive={partiallyActive} to={page.childPagesJson.slug === '/' ? '/' : page.childPagesJson.slug ? `${page.childPagesJson.slug}` : '/' + slugify(page.childPagesJson.title).toLowerCase()} className={className} activeClassName={activeClassName} onClick={onClick}>
      {children ? children : page.childPagesJson.title}
    </Link>
  ) : <Link to={to}>{children}</Link>
}