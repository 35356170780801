import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { ForestryLink } from '../../../../utilities'

import * as styles from './mainLinks.module.scss'

export default ({ }) => {
  
  const { site: { siteMetadata: { footer: { mainLinks } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        footer {
          mainLinks {
            label
            page
          }
        }
      }
    }
  }`)

  return (
    <div className={styles.container}>
      <div className={styles.links}>
        {mainLinks.map(link => (
          <ForestryLink key={link.page} className={styles.link} activeClassName={styles.active} to={link.page}>{link.label}</ForestryLink>
        ))}
      </div>
    </div>
  )
}