import React, { useState, useRef, useEffect, useCallback, useContext } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './speeds.module.scss'
import Wave from 'react-wavify'
import CountUp from 'react-countup'
import ParseRichText from './parseRichText'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import { Context } from '../../../context'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const arrowRight = 'chevron-down.svg'
  const [selected, setSelected] = useState("");
  const [mobileCurrent, setMobileCurrent] = useState('Bixby');
  const [showSelect, setShowSelect] = useState(false);
  const [pause, setPause] = useState(true);
  const [toggle, setToggle] = useState(false);
  // const [infinite, setInfinite] = useState(true);
  const [locations, setLocations] = useState(null);
  const [first, setFirst] = useState(null);
  const [middle, setMiddle] = useState(null);
  const [last, setLast] = useState(null);
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 80: 30)
  const [currentElement, setCurrentElement] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const ref = useRef(null);
  const context = useContext(Context);
  
  const locationsNode = useCallback(node => {
    if (node !== null) {
      setLocations(node);
    }
  }, [toggle]);

  const firstNode = useCallback(node => {
    if (node !== null) {
      setFirst(node);
      // setInfinite(node.getBoundingClientRect().right > window.innerWidth);
    }
  }, [toggle]);

  const middleNode = useCallback(node => {
    if (node !== null) {
      setMiddle(node);
    } else setMiddle(null)
  }, [toggle]);

  const lastNode = useCallback(node => {
    if (node !== null) {
      setLast(node);
    } else setLast(null)
  }, [toggle]);

  if (block.speedsCategories.length) {
    block.speedsCategories.forEach((category, i) => {
      category.locations.forEach((location, i) => {
        if (location.name === 'Bixby') {
          let temp = category.locations[0];
          category.locations[0] = location;
          category.locations[i] = temp;
        }
      })
    })
  }

  useEffect(() => {
    const changeWidth = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
        setAmplitude(window.innerWidth / 40)
      }, 1000)
    }

    changeWidth();
  }, [context])
  
  useEffect(() => {
    if (!context.prefersReducedMotion && ref.current && ref.current.getBoundingClientRect().top < (context.scroll + window.innerHeight) && ref.current.getBoundingClientRect().bottom > 0) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
  }, [context, locations])

  useEffect(() => {
    if (locations && last) {
      locations.style.transform = 'translateX(0)';
      locations.style.transition = "none";
      locations.style.scrollBehavior = 'auto';
      scrollTo(first.nextSibling);
      locations.style.scrollBehavior = 'smooth';
    } 
  }, [locations, width, toggle, last])

  const moveTo = (element) => {
    if (!context.prefersReducedMotion) {
    setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
    const elementWidth = element.offsetWidth;
    const elementTarget = (width / 2) - (elementWidth / 2);
    const left = locations.getBoundingClientRect().left;
    const elementLeft = element.getBoundingClientRect().left;
    const target =  elementTarget - (elementLeft - left);
    locations.style.transform = `translateX(${target}px)`;
    setSelected(element.innerText)
    setCurrentElement(element);
  }

  const scrollTo = (element) => {
    if (!context.prefersReducedMotion) {
    setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
    if (element) {
      const elementWidth = element.offsetWidth;
      const left = locations.scrollLeft;
      const target = (width / 2) - (elementWidth / 2);
      const elementLeft = element.getBoundingClientRect().left;
      locations.scrollLeft = left + (elementLeft - target);
      if (first && middle && locations)
        reset(element);
      setSelected(element.innerText);
      setCurrentElement(element);
    }
  }

  const reset = (element) => {
    const left = element.getBoundingClientRect().left;
    const middleLeft = middle.getBoundingClientRect().left;
    const firstLeft = first.getBoundingClientRect().left;
    const children = locations.children;
    if (left > middleLeft || left <= firstLeft ) {
      for (let i = 0; i < children.length; i++) {
        const text = children[i].innerText;
        const childLeft = children[i].getBoundingClientRect().left
        if (childLeft > firstLeft && text === element.innerText) {
          setTimeout(() => {
            locations.style.scrollBehavior = 'auto';
            scrollTo(children[i]);
            locations.style.scrollBehavior = 'smooth';
          }, 500);
          break;
        }
      }
    }
  }

  const handleClick = e => {
    if (locations) {
        scrollTo(e.target);
    }
  }

  const handleMobileClick = e => {
    if (!context.prefersReducedMotion) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 1000);
    }
    setMobileCurrent(e.target.innerText);
    setShowSelect(false);
    setSelected(e.target.innerText.replace(' ',''));
  }

  const handleLeft = () => {
    if (currentElement) {
      locations.style.scrollBehavior = 'smooth';
      scrollTo(currentElement.previousSibling);
    }
    else if (currentElement && currentElement.previousSibling) {
      locations.style.transition = "all .5s";
      moveTo(currentElement.previousSibling);
    }
  }
  
  const handleRight = () => {
    if (currentElement) {
      locations.style.scrollBehavior = 'smooth';
      scrollTo(currentElement.nextSibling);
    }
    else if (currentElement && currentElement.nextSibling) {
      locations.style.transition = "all .5s";
      moveTo(currentElement.nextSibling);
    }
  }
  
  const disclaimerRef = useCallback(el => {
    if (showDisclaimer && el) {
      const handleClickOutside = event => {
        if (el && !el.contains(event.target)) {
            setShowDisclaimer(false);
            return document.removeEventListener('mousedown', handleClickOutside)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
    }
  }, [showDisclaimer])

  return (
    <div className={styles.section} id={block.type} ref={ref}>
      <div className={styles.backgroundContainer}>
        <div className={styles.overlay}>
          <Wave fill="#184b86"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude,
              speed: .2,
              points: 4
            }}
          >
          </Wave>
        </div>
        <div className={styles.waveContainer}>
          <div className={styles.waves}>
            <div className={styles.waveOne}>
              <Wave fill="url(#gradient)"
                paused={pause}
                options={{
                  height: 100,
                  amplitude: amplitude * 3,
                  speed: .2,
                  points: 3
                }}
              >
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(30)">
                    <stop offset="0%"  stopColor="#1397c7" />
                    <stop offset="70%" stopColor="#003D79" />
                  </linearGradient>
                </defs>
              </Wave>
            </div>
            <div className={styles.waveTwo}>
              <Wave fill="url(#gradient2)"
                paused={pause}
                options={{
                  height: 100,
                  amplitude: amplitude * 2.4,
                  speed: .2,
                  points: 6
                }}
              >
                <defs>
                  <linearGradient id="gradient2" gradientTransform="rotate(50)">
                    <stop offset="-20%"  stopColor="#1397c7" />
                    <stop offset="70%" stopColor="#033c75" />
                  </linearGradient>
                </defs>
              </Wave>
            </div>
            <div className={styles.waveThree}>
              <Wave fill="url(#gradient3)"
                paused={pause}
                options={{
                  height: 100,
                  amplitude: amplitude * 3,
                  speed: .2,
                  points: 7
                }}
              >
                <defs>
                  <linearGradient id="gradient3" gradientTransform="rotate(60)">
                    <stop offset="0%"  stopColor="#1397c7" />
                    <stop offset="70%" stopColor="#003D79" />
                  </linearGradient>
                </defs>
              </Wave>
            </div>
        </div>
      </div>
        <div className={styles.white}></div>
        <Image src={block.background} className={styles.background} container/>
      </div>
      <div className={styles.container}>
        <button onClick ={() => { 
          setSelected('Bixby');
          setMobileCurrent('Bixby');
          setToggle(!toggle);
        }} className={styles.toggleContainer}>
          <div className={styles.switcher}>
            <div className={styles.toggle + ` ${toggle ? `${styles.on}` : ""}`}>{toggle && block.speedsCategories[1] ? block.speedsCategories[1].title : block.speedsCategories[0] ? block.speedsCategories[0].title : ""}</div>
            <p className={styles.categoryOne}>{block.speedsCategories && block.speedsCategories[0].title}</p>
            <p className={styles.categoryTwo}>{block.speedsCategories && block.speedsCategories[1].title}</p>
          </div>
        </button>
        <h2 className={styles.heading}>{block.blockTitle}</h2>
        <div className={styles.locationsMobile}>
          <button onClick={() => setShowSelect(!showSelect)} className={styles.current} >
            {mobileCurrent}
            <span><Image src={arrowRight} className={styles.arrow} />
            </span>
          </button>
          <div className={styles.mobileSelect + ` ${showSelect ? `${styles.show}` : ""}`}>
            {block.speedsCategories[toggle ? 1 : 0].locations.filter(location => location.name !== mobileCurrent).map((location, i) => (
              <button key={i} onClick={handleMobileClick} className={styles.location} >
                {location.name}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.locations} ref={locationsNode}>
          {block.speedsCategories[toggle ? 1 : 0].locations.map((location, i) => (
            <button className={selected === location.name.replace(" ","") ? `${styles.selected}` : ""} onClick={handleClick} key={i} ref={firstNode}>{location.name.replace(" ","")}</button>
          ))}
          {block.speedsCategories[toggle ? 1 : 0].locations.map((location, i) => (
            <button className={selected === location.name.replace(" ","") ? `${styles.selected}` : ""} onClick={handleClick} key={i} ref={middleNode}>{location.name.replace(" ","")}</button>
          ))}
          {block.speedsCategories[toggle ? 1 : 0].locations.map((location, i) => (
            <button className={selected === location.name.replace(" ","") ? `${styles.selected}` : ""} onClick={handleClick} key={i} ref={lastNode}>{location.name.replace(" ","")}</button>
          ))}
        </div>
        <p className={styles.speedsUpTo}>Speeds up to:</p>
        <div className={styles.speeds}>
          {(currentElement && currentElement.previousSibling) &&
          <button onClick={handleLeft} className={styles.left}><Image src={block.arrowLeft} className={styles.arrow} /></button>}
          {block.speedsCategories[toggle ? 1 : 0].locations.map((location, i) => (
            <div key={i} className={styles.speedContainer + ` ${selected === location.name.replace(" ","") ? `${styles.selected}` : ""}`}> 
              <div className={styles.speed}>
                <div className={styles.speedType}>Download</div>
                <Image src={block.downloadIcon} className={styles.icon} />
                {selected === location.name.replace(" ","") &&
                <p className={styles.count}><CountUp decimals={1} useEasing={false} duration={.6} end={location.download} /><span className={styles.plus}>{toggle && '+'}</span></p>}
                <p className={styles.gig}>Gig</p>
              </div>
              <div className={styles.speed}>
                <div className={styles.speedType}>Upload</div>
                <Image src={block.uploadIcon} className={styles.icon} />
                {selected === location.name.replace(" ","") &&
                <p className={styles.count}><CountUp decimals={1} useEasing={false} duration={.6} end={location.upload} /></p>}
                <p className={styles.gig}>Gig</p>
              </div>
              <div className={styles.disclaimer + ` ${showDisclaimer ? `${styles.show}` : ""}`} ref={disclaimerRef}><ReactMarkdown>{location.disclaimer}</ReactMarkdown></div>
            </div>
          ))}
          {(currentElement && currentElement.nextSibling) &&
          <button onClick={handleRight} className={styles.right}><Image src={block.arrowRight} className={styles.arrow} /></button>}
        </div>
        <div className={styles.subHeading}>
          <ParseRichText 
            text={block.subHeading}  
            setShowDisclaimer={setShowDisclaimer}
          />
        </div>
        <div className={styles.buttonContainer}>
          {toggle && <SharedTextButton button={block.speedsCategories[1].sharedTextButton} />}
          {!toggle && <SharedTextButton button={block.speedsCategories[0].sharedTextButton} />}
        </div>
      </div>
    </div>
  )
}