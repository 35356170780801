import algoliasearch from "algoliasearch"
import React, { useEffect, useRef, useState } from "react"
import SearchBox from "./searchBox"

export default ({search, setSearch, setCount, setResults }) => {
  const client = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )
  const queries = [
    {
      indexName: 'Blog',
      query: search,
    }
  ];
  
  const filterAttributes = hit => {
    let matchedFull = [];
    let matchedPartial = [];
    let data = {};
  
    const filter = (data) => {
      if (data && data['title'] && data['text']) {
        const titleMatch = data['title']['matchLevel'];
        const textMatch = data['text']['matchLevel'];

        if (titleMatch === 'full' || textMatch === 'full')
          matchedFull.push(data);
        else if (titleMatch === 'partial' || textMatch === 'partial')
          matchedPartial.push(data);
      }
    }

    const highlights = hit['_highlightResult'];
    
    if (highlights['body']) {
      data = {
        title: highlights['title'],
        text: highlights['body'],
        relativePath: hit['relativePath'],
        category: hit['category'],
        date: hit['date'],
        slug: hit['slug']
      }
      filter(data);
    }
      
    return { matchedFull, matchedPartial }
  }

  useEffect(() => {
    if (search) {
      client.multipleQueries(queries).then(({ results }) => {
        let fullResults = [];
        let partialResults = [];
        results.forEach(result => {
          result.hits.forEach(hit => {
            const { matchedFull, matchedPartial } = filterAttributes(hit);
            fullResults = fullResults.concat(matchedFull);
            partialResults = partialResults.concat(matchedPartial);
          })
        })
        setResults([...fullResults, ...partialResults])
        setCount(fullResults.length + partialResults.length)
      });
    }
  }, [search]);

  return (
    <SearchBox search={search} setSearch={setSearch} />
  )
}