import React from 'react'
import ReactMarkdown from 'react-markdown';

export default ({text, setShowDisclaimer}) => {
  const split = text.replace("ⓘ", "");

  const handleClick = e => {
    setShowDisclaimer(true);
  }

  return (
    <>
      {<ReactMarkdown>{split}</ReactMarkdown>}<button onClick={handleClick}>ⓘ</button>
    </>
  )
}