
import React, { useState } from 'react'

import { Columns, Contact, Copyright, MainLinks, Social } from './blocks'

import * as styles from './footer.module.scss'

export default () => {  
  const [activeMenu, setActiveMenu] = useState(undefined);
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.background}></div>
        <MainLinks />
        <Columns />
        <Social />
        <div className={styles.contactSignUp}>
          <Contact />
        </div>
        <Copyright />
        {/* <a href='http://station8branding.com' target='_blank' rel='noreferrer' className={styles.station8}>Web design by Station8</a> */}
      </div>
    </footer>
  )
}