import React, { useEffect } from 'react'

import * as styles from './hubspotForm.module.scss'

const HubspotForm = ({ block: { portalId, formId } }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId,
          formId,
          target: '#hubspotForm'
        })
      }
    })
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.form} id='hubspotForm' />
    </section>
  )
}

export default HubspotForm
