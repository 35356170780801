import React from 'react'
import { Image } from '../../../../utilities'
import * as styles from './devices.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'

export default ({ block }) => {


  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {block.devices.map((device, i) => 
          <div key={device.devicesModel} className={styles.deviceContainer}>
            <div className={styles.device}>
              <Image src={device.deviceBrandLogo} className={styles.logo} container/>
              <Image src={device.devicesImage} className={styles.image} container/>
              <p className={styles.model}>{device.devicesModel}</p>
              <SharedTextButton button={device.sharedTextButton} file={device.deviceSpecs} />
              <div className={styles.icons}>
                {device.deviceBluetooth &&
                  <Image src={'/001-bluetooth.png'} className={styles.icon} container />}
                {device.deviceWifi &&
                  <Image src={'/002-wifi.png'} className={styles.icon} container />}
                {device.deviceUsb &&
                  <Image src={'/003-usb.png'} className={styles.icon} container />}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}