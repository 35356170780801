import React, { useState, useRef, useEffect, useContext } from 'react'
import { Image } from '../../../../utilities'
import * as styles from './techProtect.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import Wave from 'react-wavify';
import { Context } from '../../../context'
import CountUp from 'react-countup'
import ReactMarkdown from 'react-markdown';

export default ({ block }) => {
  const [pause, setPause] = useState(true);
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : null);
  const [showCounters, setShowCounters] = useState(false);
  const [amplitude, setAmplitude] = useState(typeof window !== 'undefined' ? window.innerWidth / 30 : 30)
  const context = useContext(Context);
  const ref = useRef(null);
  const counters = useRef(null)
  const style = typeof window !== 'undefined' ? document.documentElement.style : null;

  if (block.featureColumnsFooter && style && block.featureColumnsFooter.length > 200)
    style.setProperty('--wave-height', '105vw');
  else if (style)
    style.setProperty('--wave-height', '90vw');

  useEffect(() => {
    const changeWidth = () => {
      setTimeout(() => {
        setWidth(window.innerWidth);
        setAmplitude(window.innerWidth / 30)
      }, 1000)
    }
    changeWidth();
  }, [context])
  
  useEffect(() => {
    if (!context.prefersReducedMotion && ref.current && ref.current.getBoundingClientRect().top < (context.scroll + window.innerHeight) && ref.current.getBoundingClientRect().bottom > 0) {
      setPause(false);
      setTimeout(() => {
        setPause(true);
      }, 600);
    }
    if (counters.current && counters.current.getBoundingClientRect().top < (context.scroll + window.innerHeight))
      setShowCounters(true);
  }, [context])

  return (
    <div className={styles.section} ref={ref} id={block.type}>
      <div className={styles.waves}>
        <div className={styles.waveOne}>
          <Wave fill="url(#gradient)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude,
              speed: .2,
              points: 3
            }}
          >
            <defs>
              <linearGradient id="gradient" gradientTransform="rotate(30)">
                <stop offset="0%"  stopColor="#1397c7" />
                <stop offset="70%" stopColor="#003D79" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.waveTwo}>
          <Wave fill="url(#gradient2)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude * .8,
              speed: .2,
              points: 6
            }}
          >
            <defs>
              <linearGradient id="gradient2" gradientTransform="rotate(50)">
                <stop offset="-20%"  stopColor="#1397c7" />
                <stop offset="70%" stopColor="#033c75" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
        <div className={styles.waveThree}>
          <Wave fill="url(#gradient3)"
            paused={pause}
            options={{
              height: 100,
              amplitude: amplitude,
              speed: .2,
              points: 7
            }}
          >
            <defs>
              <linearGradient id="gradient3" gradientTransform="rotate(60)">
                <stop offset="0%"  stopColor="#1397c7" />
                <stop offset="70%" stopColor="#003D79" />
              </linearGradient>
            </defs>
          </Wave>
        </div>
      </div>
      <div className={styles.container}>
        <h2 className={styles.title}>{block.blockTitle}</h2>
        {block.techProtectBottomText &&
        <ReactMarkdown className={styles.text}>{block.techProtectBottomText}</ReactMarkdown>} 
        <div className={styles.features}>
          {block.techProtectFeatureGrid.map((feature, i) => (
            <div key={i} className={styles.feature}>
              <Image src={feature.icon} className={styles.icon} />
              <p className={styles.featureText}>{feature.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <p className={styles.footerText}>{block.techProtectFooterText}</p>
          <SharedTextButton button={block.sharedTextButton} />
        </div>
      </div>
    </div>
  )
}