// extracted by mini-css-extract-plugin
export var caption = "bx_fS";
export var grey = "bx_c2";
export var heading = "bx_G";
export var image = "bx_k";
export var left = "bx_bH";
export var reverse = "bx_cZ";
export var right = "bx_bT";
export var section = "bx_j";
export var video = "bx_fT";
export var videoContainer = "bx_cc";
export var videoWrapper = "bx_cd";