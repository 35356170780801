// extracted by mini-css-extract-plugin
export var background = "bH_z";
export var backgroundContainer = "bH_fh";
export var bottomWave = "bH_cX";
export var buttons = "bH_ck";
export var container = "bH_b";
export var content = "bH_b2";
export var icon = "bH_br";
export var iconContainer = "bH_b0";
export var middleWave = "bH_cW";
export var negativeTop = "bH_gY";
export var overlap = "bH_g0";
export var section = "bH_j";
export var selected = "bH_bb";
export var tab = "bH_dD";
export var tabText = "bH_dC";
export var title = "bH_g";
export var topWave = "bH_gZ";