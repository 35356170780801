import React, { useEffect, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Image } from '../../../../utilities'
import * as styles from './testimonials.module.scss'
import ReactPlayer from 'react-player'
import ReactMarkdown from 'react-markdown'

export default ({ block }) => {
  const arrowLeft = 'arrow-left.svg';
  const arrowRight = 'arrow-right.svg';

  const ref = useRef<HTMLDivElement>(null)
  const [selected, setSelected] = useState(0);
  const { allContentJson: { nodes } } = useStaticQuery(graphql`
  {
    allContentJson(filter: 
      {
        testimonialsBusiness: {elemMatch: {testimonial: {ne: null}, ignore: {ne: true}}},
        testimonialsResidential: {elemMatch: {testimonial: {ne: null}, ignore: {ne: true}}},
        
      }
    ) {
      nodes {
        testimonialsBusiness {
          url
          video_file
          testimonial
          signature
          image
          type
        }
        testimonialsResidential {
          url
          video_file
          testimonial
          signature
          image
          type
        }
      }
    }
  }`)
 
  let testimonials = null;

  if (block.testimonialsType && block.testimonialsType === 'Business')
    testimonials = nodes[0] && nodes[0].testimonialsBusiness ? nodes[0].testimonialsBusiness : null;
  else if (block.testimonialsType && block.testimonialsType === 'Residential') {
    testimonials = nodes[0] && nodes[0].testimonialsResidential ? nodes[0].testimonialsResidential : null;
  }
  
  const length = testimonials ? testimonials.length : 0;

  const handleBack = () => {
    if (selected === 0)
      setSelected(length - 1);
    else
      setSelected(selected - 1);
  }

  const handleForward = () => {
    if (selected === length - 1)
      setSelected(0);
    else
      setSelected(selected + 1);
  }

  useEffect(() => {
    const resize = () => {
      if (ref.current) {
        ref.current.style.height = (ref.current.children[selected] as HTMLDivElement).offsetHeight / window.innerWidth * 100 + (length > 1 ? 15 : 0) + "vw"
      }
    }

    resize();

    window.addEventListener("resize", resize);

    return (() => {
      window.removeEventListener("resize", resize);
    })
    
  }, [selected])

  return (
    <div className={styles.section} id={block.type} ref={ref}>
      {testimonials && testimonials.map((testimonial, i) => (
        <div key={i} className={styles.testimonial + ` ${selected === i ? `${styles.selected}` : ""}`}>
          <div className={styles.textContainer}>
            <ReactMarkdown className={styles.text}>{testimonial.testimonial}</ReactMarkdown>
            <p className={styles.signature}>{testimonial.signature}</p>
          </div>
          {testimonial.type === 'Image' ? (
            <Image src={testimonial.image} className={styles.media} />
          ) : testimonial.type === 'Video Url' ? (
            <ReactPlayer controls={true} className={styles.video} url={testimonial.url} />
          ) : testimonial.type === 'Video File' ? (
            <ReactPlayer controls={true} className={styles.video} url={testimonial.video_file} />
          ) : null}
        </div>
      ))}
      {length > 1 &&
      <div className={styles.buttons}>
        <button onClick={handleBack} className={styles.left}>
          <Image src={arrowLeft} className={styles.arrow}/>
        </button>
        <button onClick={handleForward} className={styles.right}>
          <Image src={arrowRight} className={styles.arrow}/>
        </button>
      </div>}
    </div>
  )
}