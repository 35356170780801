import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Image, } from '../../../../utilities'

import * as styles from './social.module.scss'

export default ({ }) => {

  const { site: { siteMetadata: { footer: { socialTitle, socialLinks } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        footer {
          socialTitle
          socialLinks {
            icon
            label
            url
          }
        }
      }
    }
  }`)

  return (
    <div className={styles.container}>
      <div key={socialTitle} className={styles.column}>
        <p className={styles.title}>{socialTitle}</p>
        {socialLinks.map(link => (
          <a key={link.label} href={link.url} target='_blank' rel='noreferrer' className={styles.link}><Image src={link.icon} className={styles.icon} /><span>{link.label}</span></a>
        ))}
      </div>
    </div>
  )
}