import React, { useEffect, useState, useCallback, useContext }from 'react'
import { Context } from '../../../../context'
import * as styles from './faq.module.scss'
import ReactMarkdown from 'react-markdown'
import { Image } from '../../../../../utilities'

export default({ block, faqQuestion, filter }) => {
  const [faqTimer, setFaqTimer] = useState(null);
  
  const context = useContext(Context);
  const [current, setCurrent] = useState(null);
  const style = typeof window !== 'undefined' ? document.documentElement.style : null;

  const ref = useCallback(async el => {
    if (el && el.id === faqQuestion && current !== faqQuestion) {
      el.click();
      setFaqTimer(setTimeout(() => { el.scrollIntoView({block: "center"}) }, 100));
    }
  }, [faqQuestion]);

  useEffect(() => {
    return (() => {
      clearTimeout(faqTimer)
    })
  }, [])

  const handleDrop = (e, question) => {
    const answer = e.target.lastChild ? e.target.lastChild : null;
    const height = answer ? (answer.scrollHeight + 'px') : null;
    if (current && question === current || answer.style.maxHeight === 0) {
      setCurrent('');
    } else  {
      setCurrent(question);
      style.setProperty('--faqHeight', height)
    }
  }

  const filterFaqs = (faqs) => context
    ? context[faqs].filter(x =>
      x[1].subcategory.map(x => x.subcategory).includes(filter)
    ) : []

  const filteredFaqWithHits = filterFaqs('faqWithHits')
  const filteredFaqWithoutHits = filterFaqs('faqWithoutHits')
  
  return (
    <div className={styles.section + ` ${styles.active}`}>
      <div className={styles.container}>
        {filteredFaqWithHits.map((faq, i) => (
          <button key={i} id={faq[0].trim()} onClick={(e) => handleDrop(e, faq[0])} className={styles.faq + ` ${current === faq[0] ? `${styles.active}` : ""}`} ref={ref}>
            <Image src="chevron-down-dark.svg" className={styles.arrow} />
            <ReactMarkdown className={styles.question}>{faq[0]}</ReactMarkdown>
            <ReactMarkdown className={styles.answer}>{faq[1] && faq[1].answer}</ReactMarkdown>
          </button>
        ))}
        {filteredFaqWithoutHits.map((faq, i) => (
          <button key={i} id={faq[0].trim()} onClick={(e) => handleDrop(e, faq[0])} className={styles.faq + ` ${current === faq[0] ? `${styles.active}` : ""}`} ref={ref}>
            <Image src="chevron-down-dark.svg" className={styles.arrow} />
            <ReactMarkdown className={styles.question}>{faq[0]}</ReactMarkdown>
            <ReactMarkdown className={styles.answer}>{faq[1] && faq[1].answer}</ReactMarkdown>
          </button>
        ))}
      </div>
      <div></div>
    </div>
  )
}