import React, { useContext, useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Context } from '../../../context'
import { Image, ForestryLink } from '../../../../utilities'

import * as styles from './smallLinks.module.scss'

export default ({ activeMenu, setActiveMenu }) => {
  const context = useContext(Context);

  const handleClick = link => {
    if (context && link.form) {
      context.setForm(link.form);
      context.setShowForm(true);
    }
  }

  const { site: { siteMetadata: { header: { smallLinks } } } } = useStaticQuery(graphql`
  {
    site {
      siteMetadata {
        header {
          smallLinks {
            label
            page
            url
            form
            sublinks {
              page
              url
              form
              label
              icon
            }
          }
        }
      }
    }
  }`)

  const ref = useRef(null);

  useEffect(() => {
    const handleClick = e => {
      const excluded = [
        'Business',
        'Residential',
        'My BTC',
      ]
      if (ref.current && !ref.current.contains(e.target) && activeMenu && !excluded.includes(activeMenu) && window.innerWidth > 480) {
        setActiveMenu(undefined);
      }
    }

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [activeMenu]);

  const handleLeave = () => {
    setActiveMenu(undefined);
  }

  return (
    <div className={styles.links + ` ${context && context.scrolled && !activeMenu ? `${styles.scrolled}` : ""}`}>
      {smallLinks.map((link, i) => {
        if (link.sublinks) {
          return (
            <div className={styles.linkWithSublinks} key={i}>
              <button className={styles.link} onClick={() => setActiveMenu(link.label)}>{link.label}</button>
              <div className={styles.megaMenus} ref={ref} >
                <div  className={styles.megaLink + ` ${activeMenu === link.label ? `${styles.show}` : ""}`} onMouseDown={handleLeave}>
                <div className={styles.linkContainer}>
                      <div className={styles.link}>
                        {link.label}
                      </div>
                    </div>
                  <div className={styles.shadow}></div>
                </div>
                <div className={styles.megaMenu + ` ${activeMenu === link.label ? `${styles.show}` : ""}`} style={{ height: `${2 + link.sublinks.length * 3}vw`}}>
                  <div className={styles.innerContainer}>
                    <ul>
                      {link.sublinks.map((link,idx) => (
                        <li key={`${i}${idx}`}>
                          {link.icon && <Image className={styles.linkIcon} src={link.icon} />}
                          {
                            link.url ? (
                              <a className={styles.sublink} href={link.url} target="_blank" rel="noopener noreferrer">
                                {link.label}
                              </a>
                            ) : link.form ? (
                              <button className={styles.sublink} onClick={() => handleClick(link)}>{link.label}</button>
                            ) : <ForestryLink className={styles.sublink} activeClassName={styles.active} to={link.page}>
                            </ForestryLink>
                          }
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className={styles.shadowFirst + ` ${activeMenu === link.label ? `${styles.show}` : ""}`} style={{ height: `${2 + link.sublinks.length * 3}vw`}}></div>
              </div>
            </div>
          )
        }
        return (
          link.url ? (
            <a key={i} className={styles.link} href={link.url} target="_blank" rel="noopener noreferrer">
              {link.label}
            </a>
          ) : link.form ? (
            <button key={i} className={styles.link} onClick={() => handleClick(link)}>{link.label}</button>
          ) : <ForestryLink key={i} className={styles.link} activeClassName={styles.active} to={link.page}>
          </ForestryLink>
        )
      })}
    </div>
  )
}