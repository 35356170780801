// extracted by mini-css-extract-plugin
export var bottomCircle = "bF_gP";
export var content = "bF_b2";
export var description = "bF_dl";
export var hide = "bF_C";
export var icon = "bF_br";
export var image = "bF_k";
export var imageContainer = "bF_gT";
export var left = "bF_bH";
export var nextYear = "bF_gS";
export var right = "bF_bT";
export var section = "bF_j";
export var shrink = "bF_gR";
export var title = "bF_g";
export var topCircle = "bF_gN";
export var wave = "bF_f1";
export var waveContainer = "bF_dR";
export var year = "bF_gQ";