import React, { useContext } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Context } from '../../../../../context'
import { Image } from '../../../../../../utilities'

import * as styles from './searchBox.module.scss'

export default ({ }) => {
  const context = useContext(Context);
  const handleChange = e => {
    context.setSearch(e.target.value)
  }

  return (
    <div className={styles.search }>
      <div className={styles.background}></div>
      <div className={styles.container}>
        <form>
          <input
            className={styles.input}
            type="text"
            onChange={handleChange}
            value={context && context.search}
          />
          <Image className={styles.icon} src={'/search.svg'} container />
        </form>
      </div>
    </div>
  )
}
  