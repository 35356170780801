const React = require('react')
const { ContextProvider } = require('./src/components/context')
const { ErrorBoundary } = require('react-error-boundary')
const { ErrorFallback } = require('./src/errorHandling/ErrorFallback')

// Shortcut to global styles
const style = document.documentElement.style

// Keep track of various heights
const sizeListener = () => {
  style.setProperty('--window-height', window.innerHeight + 'px')

  style.setProperty('--layout-height', window.innerHeight + 'px');
}

sizeListener()
window.addEventListener('resize', sizeListener)

exports.wrapRootElement = ({ element }) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ContextProvider>{element}</ContextProvider>
  </ErrorBoundary>
)
