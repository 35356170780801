import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import { Context } from '../../../context'
import { Image } from '../../../../utilities'
import * as styles from './feedback.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import Trim from './trim'
import Fade from 'react-reveal/Fade'
import Wave from 'react-wavify'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

export default ({ block }) => {
  const context = useContext(Context);
  const middle = useRef(null);
  const last = useRef(null);
  const ref = useRef(null);
  const [selected, setSelected] = useState('');
  const [selectedElement, setSelectedElement] = useState(null);
 
  const moveRight = () => { 
    const element = last.current;
    const container = ref.current;
    const mid = middle.current;
    let distanceOne;
    let distanceTwo;
    const width = window.innerWidth;

    if (width <= 480) {
      distanceOne = width;
      distanceTwo = width;
    } else {
      distanceOne = mid.offsetWidth * 1.05;
      distanceTwo = mid.offsetWidth * 1;
    }

    if (element && mid && container && (element.getBoundingClientRect().left < window.innerWidth)) {
      const scroll = container.scrollLeft;
      container.style.scrollBehavior = 'auto';
      container.scrollLeft = container.scrollLeft - (element.getBoundingClientRect().left - mid.getBoundingClientRect().left)  
      container.style.scrollBehavior = 'smooth';
      container.scrollLeft = container.scrollLeft + distanceOne;
    }
    else if (container) {
      container.scrollLeft = container.scrollLeft + distanceOne;
    }
  }

  const moveLeft = () => {
    const container = ref.current;
    const mid = middle.current;
    let distanceOne;
    let distanceTwo;
    const width = window.innerWidth;

    if (width <= 480) {
      distanceOne = width;
      distanceTwo = width * .95;
    } else {
      distanceOne = mid.offsetWidth * 1.05;
      distanceTwo = mid.offsetWidth * 1;
    }
    if (mid && container && (container.scrollLeft < 10)) {
      container.style.scrollBehavior = 'auto';
      container.scrollLeft = container.scrollLeft + (mid.getBoundingClientRect().left + mid.offsetWidth);  
      container.style.scrollBehavior = 'smooth';
      container.scrollLeft = container.scrollLeft - distanceTwo;
    }
    else if (container) {
      container.scrollLeft = container.scrollLeft - distanceOne;
    }
  }

  const handleClick = (e,i) => {
     setSelected(i);
     setSelectedElement(e.target);
  }

  useEffect(() => {
    const close = (e) => {
      if (selectedElement && !selectedElement.contains(e.target)) {
        setSelected('');
        setSelectedElement(null);
      }
    }
    
    document.addEventListener('mousedown', close);
    
    return () => {
      document.removeEventListener('mousedown', close);
    }

  }, [selected])

  return (
    <div className={styles.section} id={block.type}>
      <h2 className={styles.title}>{block.blockTitle}</h2>
      <h4 className={styles.subtitle}>{block.feedbackSubtitle}</h4>
      <p className={styles.body}>{block.supportCarouselBody}</p>
      <div className={styles.selectors}>
        <button className={styles.left} onClick={moveLeft}><Image src={'/arrow-left.svg'} className={styles.arrow} /></button>
        <button className={styles.right} onClick={moveRight}><Image src={'/arrow-right.svg'} className={styles.arrow} /></button>
      </div>
      <div className={styles.feedbacks} ref={ref}>
        {block.feedback.map((feedback, i) => (
          <button key={`${feedback.feedbackLastName}${i}`} className={styles.wrapper} onClick={(e) => handleClick(e,i)}>
            <div className={styles.feedback + ` ${selected === i ? `${styles.selected}` :""}`} ref={middle}>
            <div className={styles.customer}>
              <div className={styles.circle}>
                <p>{feedback.feedbackFirstName[0]}{feedback.feedbackLastName[0]}</p>
              </div>
              <div className={styles.nameLocation}>
                <p>{feedback.feedbackFirstName} {feedback.feedbackLastName}</p>
                <p>{feedback.feedbackLocation}</p>
              </div>
            </div>
            <p className={styles.text}>
              {feedback.feedbackFeedbackType === 'Residential' ? (
                ''
              ) : <b>{feedback.company} </b>}
              {selected === i ? (
                <ReactMarkdown>{feedback.feedbackMessage}</ReactMarkdown>
              ) : <Trim text={feedback.feedbackMessage} />}
            </p>
          </div>
          </button>
          
        ))}
        {block.feedback.map((feedback, i) => (
          <button key={`${i}${feedback.feedbackFirstName}`} className={styles.wrapper} onClick={(e) => handleClick(e,i)}>
            <div className={styles.feedback + ` ${selected === i ? `${styles.selected}` :""}`} ref={last}>
            <div className={styles.customer}>
              <div className={styles.circle}>
                <p>{feedback.feedbackFirstName[0]}{feedback.feedbackLastName[0]}</p>
              </div>
              <div className={styles.nameLocation}>
                <p>{feedback.feedbackFirstName} {feedback.feedbackLastName}</p>
                <p>{feedback.feedbackLocation}</p>
              </div>
            </div>
            <p className={styles.text}>
              <b>{feedback.company} </b>
              {selected === i ? (
                <>{feedback.feedbackMessage}</>
              ) : <Trim text={feedback.feedbackMessage} />}
            </p>
          </div>
          </button>
        ))}
        <div className={styles.padding}></div>
      </div>
      <div className={styles.footer}>
        <ReactMarkdown className={styles.footerText}>
          {block.feedbackFeedbackInvitation}</ReactMarkdown>
        <SharedTextButton button={block.sharedTextButton} />
      </div>
    </div>
  )
}