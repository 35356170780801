import React, { useState, useEffect, useContext, useRef } from 'react'
import * as styles from './details.module.scss'
import SharedTextButton from '../sharedTextButton/sharedTextButton'
import ReactMarkdown from 'react-markdown'


export default ({ block }) => {
  return (
    <div className={styles.section}>
        <div className={styles.textContainer}>
          <h2 className={styles.title}>{block.heading}</h2>
          <p>{block.text && <ReactMarkdown>{block.text}</ReactMarkdown>}</p>
        </div>
    </div>
  )
}